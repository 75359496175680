import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import localStorage from 'helpers/local-storage';
import simpleStore from 'helpers/simpleStore';
import { subscribe, unsubscribe } from 'helpers/global-events';
import { emit } from 'helpers/global-events';
import { authConnect, UserProp } from 'auth';
import DataProvider from 'api/data-provider';
import apiDecorator from 'api/api-decorator';
import { reportError } from 'api/api-errors';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';
import sensor from 'components/sensor';

import translates from './favorites-i18n.json';

const classes = new Bem('favorites');

@authConnect()
@apiDecorator
@sensor
@I18nHoc(translates)
export default class FavoritesLink extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    auth: UserProp,
    builder: PropTypes.func,
    builderProps: PropTypes.shape({
      project: PropTypes.string,
    }),
    countFavorite: PropTypes.number,
    otherPropsIcon: PropTypes.shape({}),
    otherPropsLink: PropTypes.shape({}),
    text: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.updateCoors = this.updateCoors.bind(this);
    this.updateCount = this.updateCount.bind(this);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      dataCount: 0
    };

    if (!props.noInrteractive) {
      subscribe('favoritesIcon', this.updateCoors);
    }
    subscribe('favoriteCount', this.updateCount);
  }

  componentDidMount() {
    const { auth } = this.props;
    if (!auth.authenticated) {
      this.favLocalStorage();

      // eslint-disable-next-line
      if (this.favStorage.length !== this.state.dataCount) this.setState({ dataCount: this.favStorage.length });
    }
    this.onHandleResize(this.sensor.getRuntime());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.auth.authenticated
      && !prevProps.auth.authenticated
      && this.state.dataCount > 0
    ) {
      this.setState({ dataCount: 0 });
      this.favLocalStorage();
      this.api.post('action/domik/actions-favorites/synchronize', {
        postData: { synchronizeData: this.favStorage }
      }).then(() => {
        localStorage.remove('favorites');
        emit('countFavoritesProvider');
      }).catch(error => reportError(error));
    }
  }

  componentWillUnmount() {
    unsubscribe('favoritesIcon', this.updateCoors);
    unsubscribe('favoriteCount', this.updateCount);
  }

  onHandleResize() {
    if (!this.props.noInrteractive) this.updateCoors();
  }

  updateCoors() {
    simpleStore.set('favoritesIcon', this.node.getBoundingClientRect());
  }

  updateCount() {
    if (this.props.auth.authenticated) return;

    const state = { ...this.state };
    this.favLocalStorage();

    state.dataCount = this.favStorage.length;
    if (state.dataCount !== this.state.dataCount) this.setState(state);
  }

  favLocalStorage() {
    const favStorage = localStorage.get('favorites') || [];
    this.favStorage = filter(favStorage, ['project', this.props.builderProps.project]);
  }

  favoriteCount() {
    const { builderProps, auth } = this.props;
    const project = builderProps.project;

    if (!auth.authenticated) {
      return this.renderCount(this.state);
    }

    return (
      <DataProvider
        url="domik/favorites/favorites"
        query={{ project, countOnly: true }}
        renderer={this.renderCount}
        updateEvent="countFavoritesProvider"
      />
    );
  }

  renderCount({ dataCount }) {
    if (dataCount === 0) return null;
    return (
      <span {...classes('text')} children={dataCount} />
    );
  }

  renderIcon() {
    const { otherPropsIcon = {} } = this.props;

    return (
      <Icon
        {...classes('icon')}
        type={iconTypes.favorites}
        {...otherPropsIcon}
      />
    );
  }

  renderText() {
    const { text } = this.props;

    if (!text) return null;

    return (
      <span {...classes('text')} children={text} />
    );
  }

  render() {
    const { className, builder, builderProps, otherPropsLink = {} } = this.props;

    return (
      <div {...classes({ extra: className })}>
        <Link
          {...classes('btn', 'favorites')}
          linkRef={ref => this.node = ref}
          builder={builder}
          builderProps={builderProps}
          blank
          {...otherPropsLink}
        >
          <span
            {...classes('btnContent')}
            title={this.i18n('favourites')}
          >
            {this.renderIcon()}
            {this.renderText()}
          </span>
          {this.favoriteCount()}
        </Link>
      </div>
    );
  }
}

/**
 * Created by Kirill on 14.05.2018.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Link from 'components/link';
import linksDomik from 'src-sites/domik/links';
import linksForum from 'src-sites/forum/links';
import linksBild from 'src-sites/bild/links';

const linkConfig = {
  domik: linksDomik,
  forum: linksForum,
  bild: linksBild
};

export default class FavoritesData extends PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.array
    ]),
    onCloseDD: PropTypes.func
  };

  renderParentsLinks(menu) {
    return map(menu, (item, index) => (
      <div key={`${index}-link`}>
        <Link
          builder={linkConfig[item.urlBuilder].pageFavorites}
          builderProps={item}
          onClick={this.props.onCloseDD}
        >
          <span>{`${item.name} (${item.count})`}</span>
        </Link>
      </div>
    ));
  }

  renderFavoritesLinks(menu) {
    return map(menu, (item, index) => {
      const builder = linkConfig[item.urlBuilder].pageFavorites;

      return (
        <div key={index}>
          {map(item.subgroups, (item_group, index_group) => (
            <div style={{ paddingLeft: 20, marginTop: 10 }} key={`${index}-link-${index_group}`}>
              <Link
                builder={builder}
                builderProps={{
                  ...item,
                  group: index_group,
                  name: item_group.name
                }}
                onClick={this.props.onCloseDD}
              >
                <span>{`${item_group.name} (${item_group.count})`}</span>
              </Link>
            </div>
          ))}
        </div>
      );
    });
  }

  render() {
    const { data = [] } = this.props;
    const menu = data[0];

    if (menu && menu.menu) {
      return (
        <div>
          <div>
            {this.renderParentsLinks(menu.menu)}
          </div>
          <hr />
          <div style={{ minHeight: 100 }}>
            {this.renderFavoritesLinks(menu.menu)}
          </div>
        </div>
      );
    }

    return 'В Избранном ничего нет';
  }
}

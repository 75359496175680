/**
 * Created by Vit on 29.05.2017.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import simpleStore from 'helpers/simpleStore';
import { subscribe, unsubscribe } from 'helpers/global-events';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

const classes = new Bem('compare');

export default class Compare extends PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  constructor(...args) {
    super(...args);
    this.updateCoors = this.updateCoors.bind(this);

    subscribe('comparisonIcon', this.updateCoors);
  }

  componentWillUnmount() {
    unsubscribe('comparisonIcon', this.updateCoors);
  }

  updateCoors() {
    simpleStore.set('comparisonIcon', this.node.getBoundingClientRect());
  }

  render() {
    const { className } = this.props;
    return (
      <div {...classes({ extra: className })} ref={el => this.node = el}>
        <Button
          {...classes('btn', 'compare')}
          iconType={iconTypes.compare}
          label="9"
          title="Список сравнения"
        />
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { authConnect, UserProp } from 'auth';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import sensor from 'components/sensor';
import {
  Menu,
  FavoritesLink,
  serverMenu
} from 'components/header';
import { iconTypes } from 'components/icon';
import { changeDevice } from 'components/view-map-search/view-search';

import linksBild from 'src-sites/bild/links';

import translates from './lower-menu-i18n.json';

import 'styles/bild/body/lower-menu/_panel-menu.scss';

const classesPanelMenu = new Bem('panelMenu');
const bemClassesMenu = new Bem('menu');
const bemClassesFavorites = new Bem('favorites');

@authConnect()
@sensor
@I18nHoc(translates)
export default class BildLowerHeader extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    mainMenu: PropTypes.shape({}),

    // Says which device is now active:
    //   - desktop
    desktop: PropTypes.bool,
    //   - phone
    phone: PropTypes.bool,

    // Auth props
    auth: UserProp,
    actionAuthLogout: PropTypes.func,
    checkPermission: PropTypes.func,
    checkPermissions: PropTypes.func,

    // Open sideMenu method
    onOpenMenu: PropTypes.func,

    // Close sideMenu method
    onCloseMenu: PropTypes.func,

    // Says sideMenu is opened
    menuOpened: PropTypes.bool,

    // Says whether user clicked account button
    accountClicked: PropTypes.bool,
  };

  constructor(...props) {
    super(...props);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'desktop'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  getLowerMenu =()=> {
    return [
      {
        builder: linksBild.sectionLanding,
        contentType: 'stocks',
        name: 'stocks',
        iconProps: {
          type: iconTypes.gift,
          substrate: `<span class="${bemClassesMenu('img', 'substrate stocks').className}"></span>`
        }
      },
      {
        builder: linksBild.sectionLanding,
        contentType: 'flatsNewbuild',
        name: 'flatsNewbuild',
        iconProps: {
          type: iconTypes.builds,
          substrate: `<span class="${bemClassesMenu('img', 'substrate newbuilds').className}"></span>`
        }
      },
      {
        builder: linksBild.sectionLanding,
        contentType: 'onMap',
        name: 'onMap',
        iconProps: {
          type: iconTypes.markerBorder,
          substrate: `<span class="${bemClassesMenu('img', 'substrate map').className}"></span>`
        }
      },
      {
        builder: linksBild.sectionLanding,
        contentType: 'developers',
        name: 'developers',
        iconProps: {
          type: iconTypes.developers,
          substrate: `<span class="${bemClassesMenu('img', 'substrate developers').className}"></span>`
        }
      }
    ];
  };

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    changeDevice.call(this, desktop, tablet, phone);
  }

  render() {
    const { device } = this.state;
    const { i18n } = this;

    if (device === 'desktop') return null;

    const { className, mainMenu } = this.props;

    return (
      <div {...classesPanelMenu({ modifiers: { light: true }, extra: className })}>
        <Menu
          mainMenu={serverMenu(this.getLowerMenu(), mainMenu, i18n)}
        />
        <FavoritesLink
          builder={linksBild.pageFavorites}
          builderProps={{ project: 'bild' }}
          otherPropsIcon={{
            type: iconTypes.favoritesBorder,
            substrate: `<span class="${bemClassesFavorites('img', 'substrate favorites').className}"></span>`
          }}
          noInrteractive
          text={i18n('favorites')}
        />
      </div>
    );
  }
}

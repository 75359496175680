import React from 'react';
import Bem from 'bemHelper';
import Link from 'components/link';

const classesFooter = new Bem('footer');

export function renderLink(sectionLink, title, name = title, otherLinkProps) {
  return (
    <div {...classesFooter('item')}>
      <Link
        {...classesFooter('link')}
        builder={sectionLink}
        builderProps={{ title }}
        rel="nofollow"
        noIndex
        {...otherLinkProps}
      >
        <span {...classesFooter('linkContent')}>
          <span {...classesFooter('text')} children={name} />
        </span>
      </Link>
    </div>
  );
}

/**
 * Created by Vit on 16.07.2018.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import sensor from 'components/sensor';
import Container from 'components/container';

import BildHeaderContent from './header-content';

import 'styles/bild/body/header/_header.scss';

const classesHeader = new Bem('header');

@sensor
export default class Header extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
    activeRoute: PropTypes.shape({}),
    landing: PropTypes.shape({})
  };

  constructor(...props) {
    super(...props);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'desktop'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;
    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  render() {
    const { device } = this.state;
    const { className, mainMenu, activeRoute, landing } = this.props;

    return (
      <header {...classesHeader({ extra: className })}>
        <Container {...classesHeader('content')}>
          <div {...classesHeader('body')} data-test="headerBody">
            <BildHeaderContent
              updateEvent="updateLink"
              mainMenu={mainMenu}
              desktop={device === 'desktop'}
              phone={device === 'phone'}
              activeRoute={activeRoute}
              landing={landing}
            />
          </div>
        </Container>
      </header>
    );
  }
}

import React from 'react';
import Bem from 'bemHelper';
import Container from 'components/container';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import AbstractScrollComponent from './abstract-scroll-component';

import 'styles/base/components/scroll-top-btn/_scroll-top-btn.scss';

const classes = new Bem('scrollTopBtn');

export default class ScrollTopBtn extends AbstractScrollComponent {
  static displayName = 'ScrollTopBtn';

  scrollSpy({ scrollTop }) {
    if (scrollTop >= 150) { // TODO: Magic number
      if (!this.state.enableBtn) this.setState({ enableBtn: true });
    } else if (this.state.enableBtn) {
      this.setState({ enableBtn: false });
    }
  }

  render() {
    if (!this.state.enableBtn) return null;

    return (
      <div
        {...classes({ extra: this.props.className })}
        style={{ transitionDuration: '0.2s' }}
      >
        <Container {...classes('container')} body>
          <Button
            {...classes('btn')}
            iconType={iconTypes.dropdown}
            onClick={this.scrollPage}
          />
        </Container>
      </div>
    );
  }
}

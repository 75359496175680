/**
 * Created by SLK on 21.12.2016.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Link from 'components/link';

import Banner from './banner';
import bannerAsidePic from './data/banner-300x240.jpg';

const classes = new Bem('endToEndBanners');

class EndToEndBanners extends PureComponent {
  static propTypes = {
    className: PropTypes.string,

    // если нужен только один баннер
    one: PropTypes.bool
  };

  render() {
    return (
      <aside {...classes({ extra: this.props.className })}>
        <div {...classes('h270')}>
          <div {...classes('asideBanner')}>
            <Banner>
              <Link
                href="#"
                rel="nofollow"
                inlineBlock
                blank
              >
                <img
                  {...classes('pic')}
                  src={bannerAsidePic}
                  alt="баннер"
                />
              </Link>
              <span {...classes('bannerSize')}>
                <span {...classes('text')}>300x250</span>
              </span>
            </Banner>
          </div>
        </div>
        {!this.props.one &&
          <div {...classes('h270')}>
            <div {...classes('asideBanner')}>
              <Banner>
                <Link
                  href="#"
                  rel="nofollow"
                  inlineBlock
                  blank
                >
                  <img
                    {...classes('pic')}
                    src={bannerAsidePic}
                    alt="баннер"
                  />
                </Link>
                <span {...classes('bannerSize')}>
                  <span {...classes('text')}>300x250</span>
                </span>
              </Banner>
            </div>
          </div>
        }
      </aside>
    );
  }
}

export default EndToEndBanners;

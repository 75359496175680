/**
 * Created by Kotkin on 30.05.17.
 */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import map from 'lodash/map';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import Link from 'components/link';
import Accordion from 'components/accordion';
import Button from 'components/button';
import DropDown from 'components/drop-down';
import Icon, { iconTypes } from 'components/icon';

const classes = new Bem('menu');
const bemClassesDD = new Bem('dropDownList');

class Menu extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.arrayOf(PropTypes.shape({})),
    activeRoute: PropTypes.shape({}),
    onCloseSideMenu: PropTypes.func,
    mobile: PropTypes.bool
  };

  /*  static componentCacheConfig = {
    strategy: "template",
    enable: true,
  }; */

  linkItem = (it, submenuRender, index = null) => {
    return (
      <Link
        {...classes('link', {
          'asBtn onImg': submenuRender
        })}
        {...it}
        onClick={() => this.closeDD(index)}
        noRedirector
      >
        <span
          {...classes(
            `${submenuRender ? 'btn' : 'link'}Content`,
            { 'overlay bottom': submenuRender }
          )}
        >
          {it.iconProps &&
            <Icon
              {...classes('icon')}
              {...it.iconProps}
            />
          }
          <span {...classes('text')} data-test="text" children={it.name || it.builderProps.name} />
        </span>
        <span {...classes('itemLine')} />
      </Link>
    );
  }

  closeDD = (index) => {
    if (this[`ddRef_${index}`]) this[`ddRef_${index}`].getInstance().close();
  }

  mobileLinkItem(item, separateItem = false, key) {
    if (separateItem) {
      return (
        <li {...classes('item')} key={key}>
          {this.mobileLinkItem(item, false, null)}
        </li>
      );
    }

    return (
      <Link
        {...classes('btn')}
        {...item}
        onClick={this.props.onCloseSideMenu}
        noRedirector
      >
        <span {...classes('btnContent')}>
          <span {...classes('text')} children={item.name} data-test="btnContent" />
        </span>
      </Link>
    );
  }

  mobileMenu(it, index) {
    const { name, children } = it;
    const hasItems = !!children;
    const last = index === this.lastItemIndex;

    if (!hasItems) return this.mobileLinkItem(it, true, index);

    if (hasItems && last) {
      return (
        <li {...classes('item')} key={index}>
          <Accordion dataView="viewMenuMobile" panelTopic={name}>
            <panel {...classes('btn')}>
              <div {...classes('subMenu')}>
                {map(children, (link, i) => (
                  <div {...classes('subMenuItem')} key={i}>
                    {this.mobileLinkItem(link)}
                  </div>
                ))}
              </div>
            </panel>
          </Accordion>
        </li>
      );
    }

    return map(children, (link, i) => this.mobileLinkItem(link, true, `${index}_${i}`));
  }

  mapTemplate = (mainMenu, submenuRender = false) => {
    const activePath = get(this, 'props.activeRoute.path');
    return map(mainMenu, (item, index) => {

      if (!item.children) {
        return (
          <li {...classes(submenuRender ? 'subMenuItem' : 'item')} key={index}>
            {submenuRender &&
              <div {...classes('imgContainer')}>
                <div {...classes('img')}>
                  {/*
                  <Thumb
                    {...classes('imgPic')}
                    image={{ id: tempBoardImages[index].image }}
                  />
                  */}
                </div>
                <div {...classes('onPhoto')}>
                  {this.linkItem(item, submenuRender)}
                </div>
              </div>
            }
            {!submenuRender && this.linkItem(item, submenuRender)}
          </li>
        );
      }
      const activeIndex = findIndex(item.children, child => get(child, 'builderProps.url') === activePath);
      return (
        <li {...classes('item')} key={index}>
          <DropDown
            {...classes('dd')}
            ref={ref => this[`ddRef_${index}`] = ref}
            wAuto
            list
          >
            <Button
              {...classes('btn', { dd: true, active: activeIndex > -1 })}
              iconSecondType={iconTypes.carretBild}
              label={item.name}
              title={item.name}
            />
            <div {...bemClassesDD('contentContainer')}>
              <div {...bemClassesDD('content')}>
                {map(item.children, (submenuItem, key) => {
                  return (
                    <Fragment key={key}>
                      {this.linkItem(submenuItem, submenuRender, index)}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </DropDown>
        </li>
      );
    });
  }

  chooseTemplate(mobile) {
    const { mainMenu } = this.props;

    if (mobile) {
      return map(mainMenu, (item, index) => this.mobileMenu(item, index));
    }

    return this.mapTemplate(mainMenu, false);
  }

  render() {
    const { mainMenu, mobile, className } = this.props;
    if (!mainMenu) return null;

    this.lastItemIndex = mainMenu.length - 1;

    return (
      <nav {...classes({ modifiers: { mobile }, extra: className })}>
        <ul {...classes('content')}>
          {this.chooseTemplate(mobile)}
        </ul>
      </nav>
    );
  }
}

export default Menu;

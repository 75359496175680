/* eslint-disable react/no-multi-comp */
/**
 * Created by Vit on 22.11.2016.
 */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import map from 'lodash/map';
import each from 'lodash/each';
import some from 'lodash/some';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import limitMax from 'helpers/limitMaxCount';
import Accordion from 'components/accordion';
import Link from 'components/link';
import Icon from 'components/icon';
import Button from 'components/button';

import apps, { groups } from 'src-sites/backoffice/apps';

import translates from './menu-i18n.json';

const ItemShape = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string,
  handler: PropTypes.string,
  children: PropTypes.array // eslint-disable-line
});

const bemClassesItem = new Bem('userMenu');

class MenuItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    item: PropTypes.oneOfType([
      PropTypes.arrayOf(ItemShape),
      ItemShape
    ]),
    onHandler: PropTypes.func,
    onCloseSideMenu: PropTypes.func,
    notification: PropTypes.shape({
      count: PropTypes.number
    })
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.state = {
      open: false
    };
  }

  onClickHandler(handler = null) {
    if (handler) this.props.onHandler(handler, this.props);
    return this.props.onCloseSideMenu();
  }

  renderApp(item) {
    const {
      name, icon, builder, handler
    } = item;
    const props = {};
    if (builder) {
      props.builder = builder;
      props.builderProps = item;
      props.noRedirector = true;
      props.rel = 'nofollow';
      // props.blank = true;
    }

    props.onClick = () => this.onClickHandler(handler);

    if (!builder) {
      return (
        <Button
          {...bemClassesItem('btn', 'menu', this.props.className)}
          iconType={icon}
          label={name}
          onClick={props.onClick}
        />
      );
    }

    const { notification } = this.props;
    return (
      <Link
        {...props}
        {...bemClassesItem('btn', 'menu', this.props.className)}
      >
        <span {...bemClassesItem('btnContent')} data-test="btnContent">
          {icon && <Icon {...bemClassesItem('icon', 'left')} type={icon} />}
          <span {...bemClassesItem('text')} children={name} />
          {notification && notification.count > 0 &&
            <span {...bemClassesItem('text', 'second')} children={limitMax(notification.count)} />
          }
        </span>
      </Link>
    );
  }

  render() {
    return this.renderApp(this.props.item);
  }
}

class MenuList extends Component {
  static propTypes = {
    className: PropTypes.string,
    onHandler: PropTypes.func,
    items: PropTypes.array, // eslint-disable-line
    onCloseSideMenu: PropTypes.func,
    notification: PropTypes.shape({})
  };

  static checkForMessages(item = {}) {
    const mesName = 'Мои сообщения';
    return (
      // Проверка на пункт меню "Мои сообщения"
      item.name === mesName ||

      // Проверка на наличие чилда "Мои сообщения"
      (item.children && some(item.children, it => it.name === mesName))
    );
  }

  render() {
    const { className, items, onHandler, onCloseSideMenu, notification } = this.props;
    return map(items, (item, key) => {
      const messages = MenuList.checkForMessages(item);

      if (item.children) {
        return (
          <div
            {...bemClassesItem('item', item.mods, className)}
            key={key}
            data-test={item.dataTest}
          >
            <Accordion
              panelTopic={item.name}
              panelIconType={item.icon}
              panelTopicRight={messages && limitMax(notification.count)}
            >
              <panel {...bemClassesItem('panel')}>
                <div {...bemClassesItem('accordionContent')}>
                  <MenuList
                    items={item.children}
                    onHandler={onHandler}
                    onCloseSideMenu={onCloseSideMenu}
                    notification={notification}
                  />
                </div>
              </panel>
            </Accordion>
          </div>
        );
      }

      return (
        <div
          {...bemClassesItem('item', item.mods, className)}
          key={key}
          data-test={item.dataTest}
        >
          <MenuItem
            key={key}
            onHandler={onHandler}
            item={item}
            onCloseSideMenu={onCloseSideMenu}
            notification={messages ? notification : null}
            name={item.name}
          />
        </div>
      );
    });
  }
}

@connect(state => ({ notification: state.notification }))
@I18nHoc(translates)
class UserNavMenu extends Component {
  static propTypes = {
    checkPermissions: PropTypes.func,
    onCloseSideMenu: PropTypes.func,
    actionAuthLogout: PropTypes.func,
    notification: PropTypes.shape({}),
    projectApps: PropTypes.shape({})
  };

  // static cache = {};

  constructor(args) {
    super(args);
    this.onHandler = this.onHandler.bind(this);
  }

  onHandler(handler, props) {
    this[handler](props);
  }

  getMenuTree() {
    // *** Build menu tree
    const menu = [];
    const _groups = cloneDeep(groups);
    const { checkPermissions, projectApps } = this.props;
    const currentApps = projectApps || apps;

    each(currentApps, (app, key) => {
      if (app.projects && app.projects.length) {
        const currentProject = __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__;
        const index = findIndex(app.projects, project => project === currentProject);
        if (index === -1) return null;
      }
      if (checkPermissions(app.perms)) {
        if (!app.group) {
          if (key === 'logout') app.name = this.i18n('exit');
          menu.push(app);
        } else {
          const grp = app.group;
          if (!_groups[grp].children) {
            _groups[grp].children = [];
            menu.push(_groups[grp]);
          }
          _groups[grp].children.push(app);
        }
      }
    });

    return menu;
  }

  handlerLogout() {
    this.props.actionAuthLogout();
  }

  render() {
    return (
      <MenuList
        items={this.getMenuTree()}
        onHandler={this.onHandler}
        onCloseSideMenu={this.props.onCloseSideMenu}
        notification={this.props.notification}
      />
    );
  }
}

export default UserNavMenu;

import each from 'lodash/each';

/**
 * Modify static menu items list with "server" items
 * and add dynamic urls from server
 * @param menuContent
 * @param serverData
 */
export default function serverMenu(menuContent, serverData, i18n) {
  const rv = [];
  each(menuContent, (item) => {
    const { builderProps = {}, contentType, ...otherProps } = item;
    if (contentType && serverData[contentType]) {
      const children =
        item.children ? serverMenu(item.children, serverData[contentType].children, i18n) : null;
      const itemName = i18n(item.name || item.contentType || serverData[item.contentType].page_name);
      const hamburgerName = item.hamburgerName ? i18n(item.hamburgerName) : itemName;
      rv.push({
        ...otherProps,
        children,
        groupId: serverData[item.contentType].group_id,
        name: itemName,
        hamburgerName,
        builderProps: {
          url: serverData[item.contentType].url,
          name: itemName,
          title: itemName,
          contentType,
          ...builderProps
        }
      });
    } else if (!item.contentType) {
      rv.push({ ...item, name: i18n(item.name || item.blockRef) });
    }
  });
  return rv;
}

/**
 * Created by PIV on 22.06.2016.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';

import 'styles/base/components/banners/_banner.scss';

const classes = new Bem('banner');

/**
 * Banner
 * <Banner>
 *   I`m a banner
 * </Banner>
 */

class Banner extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  };

  render() {
    const { children, className } = this.props;

    return (
      <div {...classes({ extra: className })} children={children} />
    );
  }
}

export default Banner;

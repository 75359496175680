import { PureComponent } from 'react';
import { animateScroll } from 'react-scroll';
import isEqual from 'lodash/isEqual';
import sensor from 'components/sensor';

@sensor
export default class AbstractScrollComponent extends PureComponent {
  constructor(...args) {
    super(...args);

    this.scrollPage = this.scrollPage.bind(this);
    this.scrollSpy = this.scrollSpy.bind(this);
    this.onScroll = this.scrollSpy;

    this.state = {
      enableBtn: false
    };

    this.mounted = false;
    this.scrollToPosition = 0;
  }

  componentDidMount() {
    this.mounted = true;
    this.scrollSpy(this.sensor.getRuntime());
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  scrollPage() {
    animateScroll.scrollTo(this.scrollToPosition, {
      duration: 250,
      smooth: true
    });
  }

  curSetState(state = this.state) {
    if (this.mounted && !isEqual(state, this.state)) this.setState(state);
  }

  scrollSpy(sensor) { // eslint-disable-line

  }
}

/**
 * Created by Vit on 29.05.2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Link from 'components/link';

const classes = new Bem('logoHeader');

export default class LogoHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    linkBuilder: PropTypes.func.isRequired,
    builderProps: PropTypes.shape({}),
    renderContent: PropTypes.func.isRequired
  };

  render() {
    const { className, linkBuilder, builderProps = {}, renderContent } = this.props;

    return (
      <div {...classes({ extra: className })} data-test="linkBuilder">
        <Link
          {...classes('link')}
          builder={linkBuilder}
          builderProps={builderProps}
        >
          <span {...classes('linkContent')} children={renderContent(classes)} />
        </Link>
      </div>
    );
  }
}

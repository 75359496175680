/**
 * Created by Kotkin on 17.01.17.
 */
/* eslint-disable import/prefer-default-export */
import React, { PureComponent } from 'react';

/**
 *
 * === withSideMenu decorator ===
 * is used to open/close sideMenu in header.
 *
 */

export function withSideMenu(WrappedComponent) {
  class HeaderWithSideMenuState extends PureComponent {
    constructor(...args) {
      super(...args);
      this.onOpenMenu = this.onOpenMenu.bind(this);
      this.onCloseMenu = this.onCloseMenu.bind(this);

      this.state = {
        menuOpened: false
      };
      this.accountClicked = false;
    }

    onOpenMenu(account) {
      if (account) {
        this.accountClicked = !this.state.menuOpened;
        return this.setState({ menuOpened: !this.state.menuOpened });
      }

      if (this.state.menuOpened) this.accountClicked = false;
      this.setState({ menuOpened: true });
    }

    onCloseMenu() {
      if (this.state.menuOpened) {
        if (this.accountClicked) this.accountClicked = false;
        this.setState({ menuOpened: false });
      }
    }

    render() {
      const props = {
        onOpenMenu: this.onOpenMenu,
        onCloseMenu: this.onCloseMenu,
        menuOpened: this.state.menuOpened,
        accountClicked: this.accountClicked
      };

      return <WrappedComponent {...this.props} {...props} />;
    }
  }

  return HeaderWithSideMenuState;
}

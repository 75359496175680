import lastIndexOf from 'lodash/lastIndexOf';
import { init } from 'components/link/builder';

// Identifier for pages in pagination
export const PAGE_ID = 'page-';

const linksForum = {
  project: 'forum',

  // ***
  // *** Main Sections
  // ***

  // Home
  sectionForum: props => ({
    href: `/${props.langPrefix.replace('/', '')}`,
    title: props.title || props.name
  }),
  // Sitemap
  sectionSitemap: props => ({
    href: '/sitemap.xml',
    title: props.title || props.name
  }),
  // Clubs
  sectionClubs: (props) => {
    const obj = {
      href: '/clubs',
      title: props.title || props.name
    };

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },
  // All user clubs
  sectionUserClubs: (props) => {
    const obj = {
      href: `/user-clubs-u${props.user_id}`,
      title: props.title || props.name
    };

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },
  // All user clubs
  sectionUserVotes: (props) => {
    const obj = {
      href: `/user-votes-u${props.user_id}`,
      title: props.title || props.name
    };

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },
  // Contests
  sectionContest: props => ({
    href: '/contest',
    title: props.title || props.name
  }),
  // Rules
  sectionRules: props => ({
    href: '/rules',
    title: props.title || props.name
  }),
  // Forum Topics
  sectionForums: (props) => {
    const obj = {
      href: `${props.forumUrl}`,
      title: props.forum_title
    };

    return obj;
  },
  // Forum Topics
  sectionForumTopics: (props) => {
    const obj = {
      href: props.forumUrl,
      title: props.forum_title || props.title,
      hash: props.hash,
      query: props.query
    };

    if (props.offset > 0) {
      // obj.href = `${obj.href}/page-${props.page}`;
      // Fucking workaround - legacy from old forum
      obj.href = obj.href.replace('.html', `-${props.offset}.html`);
    }
    return obj;
  },
  // Topic
  sectionTopic: (props) => {
    const obj = {
      href: props.topicUrl,
      title: props.topic_title,
      hash: props.hash || null
    };

    if (props.search) {
      obj.query = {
        search: props.search
      };
    }
    if (props.offset > 0) {
      obj.href = obj.href.replace('.html', `-${props.offset}.html`);
    }
    return obj;
  },
  // Investor registry
  sectionInvestorRegistry: (props) => ({
    href: props.topicInvestorsRegistryUrl,
    title: props.title
  }),
  // All user's topics
  sectionUserTopics: (props) => {
    const obj = {
      href: `/user-topics-u${props.user_id}`,
      title: props.name || props.title
    };

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },
  sectionUser: props => ({
    title: props.title
  }),
  // All user's posts
  sectionUserPosts: (props) => {
    const obj = {
      href: `/user-posts-u${props.user_id}`,
      title: props.name || props.title
    };

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },
  // Tags
  sectionTags: (props) => {
    const obj = {
      href: `/tags/${props.link || props.tag}`,
      title: props.name || props.title
    };

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },

  // Search
  sectionSearch: (props) => {
    const obj = {
      href: '/search',
      title: props.name || props.title
    };

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },
  // Topic Search
  sectionTopicSearch: (props) => {
    const obj = {
      href: `/${props.forumLastPostTitleUrl || props.topicUrl || props.paramsTitle}-t${props.forum_last_topic_id || props.topic_id || props.id}/search/${props.search}`,
      title: props.name || props.title
    };

    if (props.spage > 0) {
      obj.href = `${obj.href}/page-${props.spage}`;
      return obj;
    }
    return obj;
  },
  // Club
  pageClub: (props) => {
    const obj = {
      href: `/clubs/${props.clubUrl || props.forumUrl || props.paramsTitle}-c${props.id}`,
      title: props.forum_title || props.title
    };
    if (props.usersPage) obj.href = `${obj.href}-users`;

    if (props.page > 0) {
      obj.href = `${obj.href}/page-${props.page}`;
      return obj;
    }
    return obj;
  },
  // Last post in topic
  pagePostLastTopic: (props) => {
    const obj = {
      href: `/${props.topicUrl}-t${props.topic_id}`,
      title: props.topic_title || props.title
    };
    const hash = (props.topic_last_post_id || props.post_id) ? `#post-${(props.topic_last_post_id || props.post_id)}` : '';

    if (props.page > 1) {
      obj.href = `${obj.href}/page-${props.page}${hash}`;
      return obj;
    }
    obj.href = `${obj.href}${hash}`;
    return obj;
  },
  pageUnreadMessages: () => ({
    href: '/unread-messages',
    title: 'Непрочитанные сообщения'
  }),
  pageFilteredMessages: () => ({
    href: '/filtered-messages',
    title: 'Сообщения под фильтром'
  }),
  // Favorites
  pageFavorites: props => ({
    href: `/${props.langPrefix}auth/favorites/${props.project}`,
    title: props.name || props.title
  }),
};

function addPageSlash(url = '', page) {
  if (page) {
    return `${url}${lastIndexOf(url, '/') === url.length - 1 ? '' : '/'}${PAGE_ID}${page}`;
  }
  return url;
}

export default init(linksForum);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import map from 'lodash/map';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';

const classes = new Bem('questionForm');
const socialsConfig = {
  fb: {
    mods: 'fb',
    icon: iconTypes.socialFacebook,
    url: '#'
  },
  // google: {
  //   mods: 'google',
  //   icon: iconTypes.socialGoogle,
  //   url: '#'
  // },
  youtube: {
    mods: 'youtube',
    icon: iconTypes.socialYoutube,
    url: '#'
  },
  twitter: {
    mods: 'twitter',
    icon: iconTypes.socialTwitter,
    url: '#'
  }
};

export default class SocialsAndPhones extends PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  renderSocials() {
    return map(socialsConfig, ({ mods, icon, url }) => (
      <div {...classes('item')} key={icon}>
        <Link {...classes('link', mods)} href={url} blank external>
          <span {...classes('linkContent')}>
            <Icon {...classes('icon')} type={icon} />
          </span>
        </Link>
      </div>
    ));
  }

  render() {
    return (
      <div {...classes({ extra: this.props.className })}>
        <div {...classes('content')}>
          <div {...classes('bottomText')}>
            <div {...classes('text')}>Наш телефон: <span {...classes('tel')}>+380 (44) 235-77-85</span></div>
            <div {...classes('text')}>Работаем: <span {...classes('schedule')}>Пн-Пт с 9:00 до 18:00</span></div>
          </div>
          <div {...classes('socialContainer')}>
            {this.renderSocials()}
          </div>
        </div>
      </div>
    );
  }
}

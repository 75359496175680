/**
 * Management applications configuration
 */

import Perms from 'auth/perms';
import { iconTypes } from 'components/icon';

import linksDomik from 'src-sites/domik/links';
import linksBackoffice from 'src-sites/backoffice/links';

/**
 * Application groups
 */
const groups = {
  // grpProfile: {
  //   name: 'Личные данные',
  //   icon: iconTypes.menu
  // },
  grpAdv: {
    name: 'Реклама/Отчёты',
    icon: iconTypes.menu
  },
  grpLocation: {
    name: 'Location',
    icon: iconTypes.menu
  },
  grpMyNewbuilds: {
    name: 'Мої новобудови',
    icon: iconTypes.menu
  },
  grpObjects: {
    name: 'Объекты',
    icon: iconTypes.menu
  },
  grpTech: {
    name: 'Техническое',
    icon: iconTypes.menu
  },
  grpFinance: {
    name: 'Новостройки',
    icon: iconTypes.menu
  },
  grpForum: {
    name: 'Форум',
    icon: iconTypes.menu
  },
  grpCrmControl: {
    name: 'Управление CRM',
    icon: iconTypes.menu
  },
  grpCrm: {
    name: 'CRM',
    icon: iconTypes.menu
  },
  grpPublications: {
    name: 'Публикации',
    icon: iconTypes.menu
  },
  grpForumPanel: {
    name: 'Панель админа форума',
    icon: iconTypes.menu
  },
  grpUsersRoles: {
    name: 'Пользователи и роли',
    icon: iconTypes.menu,
    dataTest: 'usersAndRoles'
  },
  grpContent: {
    name: 'Контент и SEO',
    icon: iconTypes.menu
  },
  grpReliability: {
    name: 'Индеск надежности',
    dataTest: 'reliability',
    icon: iconTypes.menu
  },
  grpModerator: {
    name: 'Модерация',
    icon: iconTypes.menu
  },
  grpStatistic: {
    name: 'Статистика',
    icon: iconTypes.menu
  },
  grpDiscounts: {
    name: 'Управление скидками',
    icon: iconTypes.menu
  },
  grpBildControl: {
    name: 'Bild контроль',
    icon: iconTypes.menu
  }
};

/**
 * Applications
 */
const apps = {
  // *** Common
  // goSite: {
  //   builder: linksDomik.sectionDomik,
  //   name: 'Перейти на Domik.ua',
  //   icon: iconTypes.person,
  //   perms: [Perms.LOCATION_MANAGE_ALL]// TODO add permission 'admin' when it permission be ready
  // },
  // settings: {
  //   url: 'settings/',
  //   name: 'Мои настройки',
  //   icon: iconTypes.build,
  //   perms: ['$auth']
  // },
  profile: {
    builder: linksDomik.pageProfile,
    name: 'Мой профиль',
    icon: iconTypes.personCircle,
    // group: 'grpProfile',
    perms: [Perms.AUTH]// TODO add permission 'edit_own_profile' when it permission be ready
  },
  myObjects: {
    builder: linksDomik.pageMyObjects,
    name: 'Мои объявления',
    icon: iconTypes.menu,
    // group: 'grpProfile',
    perms: [Perms.OBJECT_ADD_ANY_OBJECT],
    projects: ['domik', 'backoffice', 'forum']
  },
  addAd: {
    builder: linksDomik.pageAddAd,
    name: 'Рекламные объявления на форуме',
    icon: iconTypes.menu,
    // group: 'grpProfile',
    perms: [Perms.USERADVERT_ADD],
  },
  addObjects: {
    builder: linksDomik.pageObjectAdd,
    name: 'Подать объявление',
    mods: 'objectAdd',
    icon: iconTypes.menu,
    // group: 'grpProfile',
    perms: [Perms.OBJECT_ADD_ANY_OBJECT],
    projects: ['domik', 'backoffice', 'forum']
  },
  billing: {
    builder: linksDomik.pageBilling,
    name: 'Журнал платежей',
    mods: 'billing',
    icon: iconTypes.menu,
    // group: 'grpProfile',
    perms: [Perms.AUTH],
    projects: ['domik', 'backoffice', 'forum']
  },
  apiObjects: {
    builder: linksDomik.pageObjectsApi,
    name: 'Автопубликация объявлений',
    icon: iconTypes.menu,
    //group: 'grpObjects',
    perms: [Perms.OBJECT_API],
    projects: ['domik', 'backoffice']
  },

  // *** Newbuild
  // newbuilds: {
  //   builder: linksBackoffice.newbuilds,
  //   name: 'ЖК/КГ',
  //   icon: iconTypes.menu,
  //   group: 'grpFinance',
  //   perms: ['admin']
  // },

  mortgage: {
    builder: linksBackoffice.sectionMortgage,
    name: 'Ипотека',
    icon: iconTypes.trendingUp,
    group: 'grpFinance',
    perms: ['admin'] // TODO check&change permission
  },

  installments: {
    builder: linksBackoffice.sectionInstallments,
    name: 'Рассрочка',
    icon: iconTypes.trendingUp,
    group: 'grpFinance',
    perms: ['admin'] // TODO check&change permission
  },

  // *** Advertisement
  orderAd: {
    builder: linksBackoffice.sectionOrderAd,
    name: 'Заявки Новостроек',
    icon: iconTypes.menu,
    group: 'grpAdv',
    perms: [Perms.CONTROL_ORDER_TARIFF]
  },

  newbuildAdControl: {
    builder: linksBackoffice.sectionNewbuildAdControl,
    name: 'Управление рекламой новостроек',
    icon: iconTypes.menu,
    group: 'grpAdv',
    perms: [Perms.CONTROL_ALL_TARIFF]
  },

  // *** Location
  locationStructure: {
    builder: linksBackoffice.pageLocationStructure,
    name: 'Location Structure',
    icon: iconTypes.menu,
    group: 'grpLocation',
    perms: [Perms.LOCATION_MANAGE_ALL]
  },
  locationStructureOwn: {
    builder: linksBackoffice.pageLocationStructure,
    name: 'Загальні дані',
    icon: iconTypes.menu,
    group: 'grpMyNewbuilds',
    perms: [Perms.LOCATION_MANAGE_OWN]
  },

  photoManagement: {
    builder: linksBackoffice.pagePhotoManagement,
    name: 'Фотографии ЖК',
    icon: iconTypes.menu,
    group: 'grpLocation',
    perms: [
      Perms.LOCATION_MANAGE_ALL,
      Perms.LOCATION_PHOTOGRAPHER
    ]
  },
  gis: {
    builder: linksBackoffice.pageGis,
    name: 'ГИС Domik',
    icon: iconTypes.menu,
    group: 'grpLocation',
    perms: [Perms.LOCATION_MANAGE_ALL]
  },
  geoinfo: {
    builder: linksBackoffice.pageGeoinfo,
    name: 'GeoInfo',
    icon: iconTypes.menu,
    group: 'grpLocation',
    perms: [Perms.LOCATION_MANAGE_ALL]
  },
  // *** RegionPromotionalLinks
  sectionRegionPromotionalLinks: {
    builder: linksBackoffice.sectionRegionPromotionalLinks,
    name: 'Региональные линки агенств',
    icon: iconTypes.menu,
    group: 'grpLocation',
    perms: [Perms.MANAGEMENT_REGION_PROMOTIONAL_LINK]
  },
  // *** RegionBanners
  sectionRegionBanners: {
    builder: linksBackoffice.sectionRegionBanners,
    name: 'Баннеры по регионам',
    icon: iconTypes.menu,
    group: 'grpLocation',
    perms: [Perms.LOCATION_MANAGE_ALL]
  },

  // *** Objects
  newbuildObjects: {
    builder: linksBackoffice.pageNewbuildObjects,
    name: 'Объекты в новостройках',
    icon: iconTypes.menu,
    group: 'grpObjects',
    perms: [Perms.OBJECT_MANAGE_ALL_NEWBUILD_OBJECTS]
  },
  myNewbuildObjects: {
    builder: linksBackoffice.pageNewbuildObjects,
    name: 'Планування',
    icon: iconTypes.menu,
    group: 'grpMyNewbuilds',
    perms: [Perms.OBJECT_MANAGE_OWN_NEWBUILD_OBJECTS]
  },

  // *** Tech
  icons: {
    builder: linksBackoffice.pageIcons,
    name: 'Иконки',
    icon: iconTypes.menu,
    group: 'grpTech',
    perms: [Perms.MANAGEMENT_HELPERS]
  },
  customCriterias: {
    builder: linksBackoffice.pageCustomCriterias,
    name: 'Custom Criterias',
    icon: iconTypes.menu,
    group: 'grpTech',
    perms: [Perms.USERS_TECH_SECTION]
  },
  dataView: {
    builder: linksBackoffice.pageDataView,
    name: 'Data View',
    icon: iconTypes.menu,
    group: 'grpTech',
    perms: [Perms.MANAGEMENT_DATA_VIEW]
  },
  dicts: {
    builder: linksBackoffice.pageDicts,
    name: 'Справочники',
    icon: iconTypes.menu,
    group: 'grpTech',
    perms: [Perms.MANAGEMENT_DICTS]
  },
  chargeable: {
    builder: linksBackoffice.pageChargeable,
    name: 'Платные ништяки',
    icon: 'menu',
    group: 'grpTech',
    perms: [Perms.MANAGEMENT_CHARGEABLE_SERVICES]
  },
  sysTools: {
    builder: linksBackoffice.pageTools,
    name: 'Утилиты',
    icon: 'menu',
    group: 'grpTech',
    perms: [Perms.USERS_TECH_SECTION]
  },

  // *** Forum
  forum: {
    builder: linksBackoffice.pageForum,
    name: 'Форумы',
    icon: iconTypes.menu,
    group: 'grpForum',
    perms: [
      Perms.FORUM_ADD_FORUM,
      Perms.FORUM_EDIT_ALL_FORUM
    ]
  },
  announcements: {
    builder: linksBackoffice.pagePanelAnnouncements,
    name: 'Объявления',
    icon: iconTypes.menu,
    group: 'grpForum',
    perms: [Perms.FORUM_EDIT_ALL_AD]
  },
  medals: {
    builder: linksBackoffice.pagePanelMedals,
    name: 'Медали',
    icon: iconTypes.menu,
    group: 'grpForum',
    perms: [Perms.FORUM_CONTROL_ALL_TOPIC]
  },
  contextBlocks: {
    builder: linksBackoffice.pagePanelContext,
    name: 'Контекстные блоки',
    icon: iconTypes.menu,
    group: 'grpForum',
    perms: [Perms.FORUM_CONTROL_CONTEXT]
  },

  // *** Panel Admin
  approvements: {
    builder: linksBackoffice.pagePanelApprovements,
    name: 'На одобрение',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_CONTROL_PREMODERATION_POST]
  },
  unread: {
    builder: linksBackoffice.pagePanelUnread,
    name: 'Не прочитанные',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_CONTROL_PREMODERATION_POST]
  },
  avatarPremoderation: {
    builder: linksBackoffice.pageAvatarPremoderation,
    name: 'Премодерация аватаров',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_CONTROL_PREMODERATION_AVATAR]
  },
  club_users: {
    builder: linksBackoffice.pagePanelpagePanelClubOrders,
    name: 'Заявки на вступление в клубы',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_CONTROL_ALL_TOPIC]
  },
  complaints: {
    builder: linksBackoffice.pagePanelComplaints,
    name: 'Жалобы',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_CONTROL_COMPLAINT]
  },
  autofilter: {
    builder: linksBackoffice.pagePanelAutofilter,
    name: 'Автофильтр',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_CONTROL_AUTOFILTER]
  },
  badwords: {
    builder: linksBackoffice.pageBadWords,
    name: 'База плохих слов',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_CONTROL_BAD_WORDS]
  },
  ipInstriments: {
    builder: linksBackoffice.pagePanelInstruments,
    name: 'Инструменты IP',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_READ_STATISTICS]
  },
  panelUsers: {
    builder: linksBackoffice.pagePanelUsers,
    name: 'Пользователи',
    icon: iconTypes.menu,
    group: 'grpForumPanel',
    perms: [Perms.FORUM_READ_FORUM_ROLE]
  },

  // Editors
  sysRolesEditor: {
    builder: linksBackoffice.pageSysRolesEditor,
    name: 'Редактор ролей',
    icon: iconTypes.build,
    group: 'grpUsersRoles',
    perms: [Perms.USERS_MANAGE_SYSTEM_ROLES],
    dataTest: 'pageSysRolesEditor'
  },
  allUsersEditor: {
    builder: linksBackoffice.pageAllUsersEditor,
    name: 'Пользователи',
    icon: iconTypes.build,
    group: 'grpUsersRoles',
    perms: [Perms.USERS_MANAGE_ALL],
    dataTest: 'pageAllUsersEditor'
  },

  // CRM
  companyRolesEditor: {
    builder: linksBackoffice.pageCompanyRolesEditor,
    name: 'Роли в компании',
    icon: iconTypes.build,
    group: 'grpCrmControl',
    perms: [Perms.USERS_MANAGE_COMPANY_ROLES]
  },
  companyUsersEditor: {
    builder: linksBackoffice.pageCompanyUsersEditor,
    name: 'Структура компании',
    icon: iconTypes.build,
    group: 'grpCrmControl',
    perms: [Perms.USERS_MANAGE_COMPANY]
  },
  productsCompany: {
    builder: linksBackoffice.crmProductsCompany,
    name: 'Продукты компании',
    icon: iconTypes.build,
    group: 'grpCrmControl',
    perms: [Perms.CRM_CONTROL_PRODUCTS_COMPANY, Perms.CRM_CONTROL_ALL_CRM]
  },
  salesCompany: {
    builder: linksBackoffice.crmSalesCompany,
    name: 'Воронка продаж',
    icon: iconTypes.build,
    group: 'grpCrmControl',
    perms: [Perms.CRM_CONTROL_SALES_COMPANY, Perms.CRM_CONTROL_ALL_CRM]
  },
  legalEntity: {
    builder: linksBackoffice.crmLegalEntity,
    name: 'Юридическое лицо',
    icon: iconTypes.build,
    group: 'grpCrmControl',
    // TODO Нужно будет добавить необходимые пермишены для отображения подраздела
    perms: [Perms.CRM_CONTROL_ALL_CRM]
  },
  customerTypeCompany: {
    builder: linksBackoffice.crmCustomerTypeCompany,
    name: 'Типы клиентов',
    icon: iconTypes.build,
    group: 'grpCrmControl',
    // TODO Нужно будет добавить необходимые пермишены для отображения подраздела
    perms: [Perms.CRM_CONTROL_ALL_CRM]
  },
  adPlace: {
    builder: linksBackoffice.crmAdPlace,
    name: 'Рекламные места',
    icon: iconTypes.build,
    group: 'grpCrmControl',
    // TODO Нужно будет добавить необходимые пермишены для отображения подраздела
    perms: [Perms.CRM_CONTROL_ALL_CRM]
  },
  // CRM
  customersCompany: {
    builder: linksBackoffice.crmCustomersCompany,
    name: 'Клиенты',
    icon: iconTypes.build,
    group: 'grpCrm',
    perms: [Perms.CRM_CONTROL_CUSTOMERS_COMPANY, Perms.CRM_CONTROL_ALL_CRM]
  },
  crmObjects: {
    builder: linksBackoffice.crmCompanyObjects,
    name: 'Объекты',
    icon: iconTypes.build,
    group: 'grpCrm',
    perms: [
      Perms.OBJECT_EDIT_UNIT_OBJECT,
      Perms.OBJECT_DELETE_UNIT_OBJECT,
      Perms.USERS_MANAGE_COMPANY,
      Perms.USERS_MANAGE_DEPARTMENT,
      Perms.USERS_MANAGE_BRANCH,
    ]
  },
  crmObjectSearch: {
    builder: linksBackoffice.crmSearchObjects,
    name: 'Поиск объектов',
    icon: iconTypes.build,
    group: 'grpCrm',
    perms: [
      Perms.CRM_OBJECTS_SEARCH,
    ]
  },
  partnersCompany: {
    builder: linksBackoffice.crmPartnersCompany,
    name: 'Контрагенты',
    icon: iconTypes.build,
    group: 'grpCrm',
    // TODO Нужно будет добавить необходимые пермишены для отображения подраздела
    perms: [Perms.CRM_CONTROL_ALL_CRM]
  },
  projectsCompany: {
    builder: linksBackoffice.crmProjectsCompany,
    name: 'Проекты',
    icon: iconTypes.build,
    group: 'grpCrm',
    // TODO Нужно будет добавить необходимые пермишены для отображения подраздела
    perms: [Perms.CRM_CONTROL_ALL_CRM]
  },
  tasksCompany: {
    builder: linksBackoffice.crmTasksCompany,
    name: 'Задачи',
    icon: iconTypes.build,
    group: 'grpCrm',
    // TODO Нужно будет добавить необходимые пермишены для отображения подраздела
    perms: [Perms.CRM_CONTROL_ALL_CRM]
  },
  dealCompany: {
    builder: linksBackoffice.crmDealCompany,
    name: 'Сделки',
    icon: iconTypes.build,
    group: 'grpCrm',
    // TODO Нужно будет добавить необходимые пермишены для отображения подраздела
    perms: [Perms.CRM_CONTROL_ALL_CRM]
  },

  // Publications
  publicationsControl: {
    builder: linksBackoffice.publicationsControl,
    name: 'Управление',
    icon: iconTypes.build,
    group: 'grpPublications',
    perms: [Perms.DOMIK_CONTROL_PUBLICATIONS, Perms.DOMIK_ADD_PUBLICATIONS, Perms.DOMIK_CONTROL_RUBRICS]
  },

  // Landings page management
  seoLandings: {
    builder: linksBackoffice.seoLandings,
    name: 'Landings',
    icon: iconTypes.build,
    group: 'grpContent',
    perms: [Perms.MANAGEMENT_SEO]
  },

  // Translation lists management
  translationLists: {
    builder: linksBackoffice.translationLists,
    name: 'Списки трансляции',
    icon: iconTypes.build,
    group: 'grpContent',
    perms: [Perms.MANAGEMENT_CONTENT_BLOCK]
  },

  // Translation lists management
  adUnits: {
    builder: linksBackoffice.adUnits,
    name: 'Рекламные блоки',
    icon: iconTypes.build,
    group: 'grpContent',
    perms: [Perms.MANAGEMENT_AD_UNITS]
  },

  livingComplexAnchor: {
    builder: linksBackoffice.livingComplexAnchor,
    name: 'Блок линковки ЖК',
    icon: iconTypes.build,
    group: 'grpContent',
    perms: [Perms.MANAGEMENT_SEO]
  },

  // Newbuilds reliability index management
  reliabilityNewbild: {
    builder: linksBackoffice.reliabilityNewbild,
    name: 'NewBild',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_NEWBUILD],
    dataTest: 'reliabilityNewBild'
  },

  // Developer
  reliabilityDeveloper: {
    builder: linksBackoffice.reliabilityDeveloper,
    name: 'Developer',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_DEVELOPER],
    dataTest: 'reliabilityDeveloper'
  },

  // Orders
  reliabilityOrders: {
    builder: linksBackoffice.reliabilityOrders,
    name: 'Orders',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_SEND_ORDER],
    dataTest: 'reliabilityDeveloper'
  },

  reliabilityLongTermConstruction: {
    builder: linksBackoffice.reliabilityLongTermConstruction,
    name: 'Отчёты по долгостроям',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_LONG_TERM_CONSTRUCTION]
  },

  // reliabilityPartnersBanner
  reliabilityPartnersBanner: {
    builder: linksBackoffice.reliabilityPartnersBanner,
    name: 'Баннеры для сайтов ЖК',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_DEVELOPER],
    dataTest: 'reliabilityDeveloper'
  },

  // DPI Borg
  reliabilityDPIBorg: {
    builder: linksBackoffice.reliabilityDPIBorg,
    name: 'Долги перед налоговой',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_DEVELOPER],
    dataTest: 'reliabilityDPIBorg'
  },

  // Config
  reliabilityConfig: {
    builder: linksBackoffice.reliabilityConfig,
    name: 'Config',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_SEND_ORDER],
    dataTest: 'reliabilityDeveloper'
  },

  // DPI Borg
  reliabilityOurCompany: {
    builder: linksBackoffice.reliabilityOurCompany,
    name: 'Команда',
    icon: iconTypes.build,
    group: 'grpReliability',
    perms: [Perms.RELIABILITY_NEWBUILD],
  },

  moderatorCommentLC: {
    builder: linksBackoffice.moderatorCommentLC,
    name: 'Модерация комментариев',
    icon: iconTypes.build,
    group: 'grpModerator',
    perms: [Perms.MODERATION_COMMENTS_LC],
    dataTest: 'moderatorCommentLC'
  },
  moderatorUserAdvert: {
    builder: linksBackoffice.moderatorUserAdvert,
    name: 'Модерация рекламы',
    icon: iconTypes.build,
    group: 'grpModerator',
    perms: [Perms.USERADVERT_MANAGE]
  },
  statisticUserAdvert: {
    builder: linksBackoffice.statisticUserAdvert,
    name: 'Учет рекламы',
    icon: iconTypes.build,
    group: 'grpModerator',
    perms: [Perms.USERADVERT_MANAGE]
  },
  lcAndCottageTowns: {
    builder: linksBackoffice.crmLcAndCottageTowns,
    name: 'Продукты ЖК и КП',
    icon: iconTypes.build,
    group: 'grpModerator',
    perms: [Perms.CRM_CONTROL_LC_AND_COTTAGE_TOWNS]
  },

  statNewbild: {
    builder: linksBackoffice.statNewbild,
    name: 'Статистика новостроек',
    icon: iconTypes.build,
    group: 'grpStatistic',
    perms: [Perms.STATISTIC_NEWBILD]
  },
  statOwnNewbild: {
    builder: linksBackoffice.statNewbild,
    name: 'Статистика новостроек',
    icon: iconTypes.build,
    group: 'grpMyNewbuilds',
    perms: [Perms.STATISTIC_NEWBUILD_OWN]
  },

  managementDiscounts: {
    builder: linksBackoffice.managementDiscounts,
    name: 'скидки',
    icon: iconTypes.build,
    group: 'grpDiscounts',
    perms: [Perms.MANAGEMENT_DISCOUNTS]
  },

  logout: {
    handler: 'handlerLogout',
    name: 'Выход',
    mods: 'logout',
    icon: iconTypes.logIn,
    perms: ['$auth']
  }
};

export {
  apps as default,
  groups
};

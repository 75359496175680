/**
 * Created by Kotkin on 29.05.2017.
 */
import React, { PureComponent, Children } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { animateScroll } from 'react-scroll';
import { CSSTransition } from 'react-transition-group';
import toNumber from 'lodash/toNumber';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import noScroll from 'helpers/noScroll';
import { UserProp } from 'auth';
import sensor from 'components/sensor';
// import Accordion from 'components/accordion';
import Button from 'components/button';
import { iconTypes } from 'components/icon';
import LangSwitch from 'components/lang-switch';

import SocialsAndPhones from './socials-and-phones';

import translates from './menu-i18n.json';

const classes = new Bem('sideMenu');

/**
 * === SideMenu Component ===
 *
 * Родительский компонент для всех SideMenu в хедерах проектов.
 * От него необходимо наследоваться.
 *
 * пример можно посмотреть в SideMenu проекта domik.
 */

@sensor
@I18nHoc(translates)
export default class SideMenu extends PureComponent {
  static propTypes = {
    desktop: PropTypes.bool.isRequired,
    phone: PropTypes.bool.isRequired,
    menuOpened: PropTypes.bool.isRequired,
    onMenuClose: PropTypes.func.isRequired,
    mainMenu: PropTypes.arrayOf(PropTypes.shape({})),
    activeRoute: PropTypes.shape({}),
    accountClicked: PropTypes.bool,
    socialsAndPhones: PropTypes.bool,
    auth: UserProp,
    actionAuthLogout: PropTypes.func,
    checkPermission: PropTypes.func,
    checkPermissions: PropTypes.func
  };

  static defaultProps = {
    socialsAndPhones: true
  };

  static renderSingleElement(props) {
    const children = Children.toArray(props.children);
    return children[0] || null;
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.onOverlayClick = this.onOverlayClick.bind(this);
    this.onSwipeStart = this.onSwipeStart.bind(this);
    this.onSwipeMove = this.onSwipeMove.bind(this);
    this.onSwipeEnd = this.onSwipeEnd.bind(this);

    this.slideTimer = null;
    this.enableNoScroll = true;
    this.state = {
      clientRendered: false
    };
  }

  componentDidMount() {
    this.setState({ clientRendered: true }); // eslint-disable-line
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const { auth = {}, menuOpened, desktop, accountClicked } = this.props;

    if (this.enableNoScroll && prevProps.desktop !== desktop && menuOpened) {
      this.props.onMenuClose();
    }

    if (prevProps.menuOpened !== menuOpened && this.enableNoScroll) {
      noScroll();
    }

    if (
      auth.authenticated && !desktop &&
      menuOpened && accountClicked
    ) {
      setTimeout(() => {
        if (this.userAccordion) this.userAccordion.openContent();
      }, transitionDuration * 2);
    }
  }

  onOverlayClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onMenuClose();
  }

  onSwipeStart(e) {
    if (this.props.desktop) return;

    this.clientX = e.changedTouches[0].clientX;
    this.clientY = e.changedTouches[0].clientY;
    this.menuMobileContainer.style.transitionDuration = '0';
  }

  onSwipeMove(e) {
    if (!this.props.desktop && this.clientY - e.changedTouches[0].clientY < 20) this.menuMobileContainer.style.left = `-${(this.clientX - e.changedTouches[0].clientX) / 10}rem`;
  }

  onSwipeEnd(e) {
    if (this.props.desktop) return this.props.onMenuClose();

    if (
      this.clientX - e.changedTouches[0].clientX > 10 &&
      this.clientY - e.changedTouches[0].clientY < 10
    ) {
      this.menuMobileContainer.style.left = '';
      this.menuMobileContainer.style.transitionDuration = '';
      return this.props.onMenuClose();
    }

    return this.menuMobileContainer.style.left = '';
  }

  scrollToMenuItem(menu) { // eslint-disable-line
    return animateScroll.scrollTo(menu.offsetTop, {
      duration: 500,
      smooth: true,
      containerId: 'menuMobileContainer'
    });
  }

  renderPostAds(classes) { // eslint-disable-line

  }

  renderUserMenu() {

  }

  renderMainMenu() {

  }

  renderSearchTouch() {

  }

  renderContent(desktop) {
    const { i18n } = this;

    if (!desktop) {
      const {
        // auth, accountClicked,
        socialsAndPhones, onMenuClose, activeRoute
      } = this.props;

      return (
        <div
          {...classes('content', { desktop })}
          id="menuMobileContainer"
          ref={ref => this.menuMobileContainer = ref}
        >
          {this.renderSearchTouch()}
          {!desktop &&
            <Button
              {...classes('btn', 'close')}
              onClick={onMenuClose}
              iconSecondType={iconTypes.clear}
              title={i18n('btnClose')}
              label={i18n('menu')}
            />
          }
          {/*
          <div {...classes('breadcrumbs')}>
            <span {...classes('breadcrumbsContent')}>
              <span {...classes('text')}>breadcrumbs</span>
            </span>
          </div>
          */}
          {this.renderMenu()}
          {this.renderPostAds(classes)}
          <div {...classes('itemEmpty')} />
          {/* auth.authenticated &&
            <div
              {...classes('userMenuMobile', { accountClicked })}
              ref={ref => this.userMenuMobile = ref}
            >
              <Accordion
                {...classes('item')}
                // dataView="viewMenuUserMobile"
                ref={ref => this.userAccordion = ref}
                openCallBack={() =>
                  this.scrollToMenuItem(this.userMenuMobile)
                }
                panelTopic="Мое меню"
              >
                <panel {...classes('panel')}>
                  <div {...classes('subMenu')}>
                    {this.renderUserMenu()}
                  </div>
                </panel>
              </Accordion>
            </div>
          */}
          {activeRoute &&
            <LangSwitch {...classes('langSwitch')} activeRoute={activeRoute} />
          }
          {socialsAndPhones &&
            <div {...classes('questionForm')}>
              <SocialsAndPhones />
            </div>
          }
        </div>
      );
    }

    return (
      <div
        {...classes('content', { desktop })}
        ref={ref => this.menuContainer = ref}
      >
        {this.renderMenu()}
      </div>
    );
  }

  renderMenu() {
    const { auth, desktop } = this.props;

    if (!desktop) {
      return (
        <div
          {...classes('menuContainer')}
          onTouchStart={this.onSwipeStart}
          onTouchMove={this.onSwipeMove}
          onTouchEnd={this.onSwipeEnd}
        >
          {this.renderMainMenu()}
        </div>
      );
    }
    if (auth.authenticated) {
      return this.renderUserMenu();
    }
  }

  render() {
    if (!this.state.clientRendered) return null;

    const transitionDuration = toNumber(this.sensor.getVariable('timeDuration'));
    const { desktop, menuOpened } = this.props;

    return (
      ReactDOM.createPortal(
        <div
          {...classes({
            modifiers: { opened: menuOpened }
          })}
        >
          <div //eslint-disable-line
            {...classes('overlay', {
              show: menuOpened
            })}
            onClick={this.onOverlayClick}
            onTouchStart={this.onSwipeStart}
            onTouchMove={this.onSwipeMove}
            onTouchEnd={this.onSwipeEnd}
          />
          <CSSTransition
            in={menuOpened}
            onEntered={() => this.setState({ tRendered: true })}
            onExited={() => this.setState({ tRendered: false })}
            classNames={{
              enter: 'sideMenu__content_enter',
              enterActive: 'sideMenu__content_enterActive',
              exit: 'sideMenu__content_leave',
              exitActive: 'sideMenu__content_leaveActive'
            }}
            timeout={{ enter: transitionDuration, exit: transitionDuration }}
          >
            {(menuOpened || this.state.tRendered) ?
              this.renderContent(desktop) : <div></div>
            }
          </CSSTransition>
        </div>,
        document.getElementById('content')
      )
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import cacheTerms from 'helpers/cacheTerms';
import localStorage from 'helpers/local-storage';
import DataProvider from 'api/data-provider';
import Counter from 'subsys/counter';
import Link from 'components/link';
import sensor from 'components/sensor';
// import { Thumb } from 'components/image';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import 'styles/base/components/banners/_banner-header.scss';

const bemClasses = new Bem('bannerHeader');

const oneDay = 1000 * 60 * 60 * 24;

@sensor
export default class BannerHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    geolocation: PropTypes.shape({}),
    activeRoute: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;
    this.counterEntity = null;
    this.counterRendered = 0;

    let showBanner = true;
    const hideHeaderBanner = localStorage.get('hideHeaderBanner');
    const dateDifference = (Date.now() - new Date(hideHeaderBanner)) / oneDay;

    if (hideHeaderBanner && dateDifference < 5) showBanner = false

    this.state = {
      showBanner,
      device: 'phone',
      mounted: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return get(this, 'props.activeRoute.path') !== get(nextProps, 'activeRoute.path') ||
      this.state.showBanner !== nextState.showBanner ||
      this.state.mounted !== nextState.mounted
      ;
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;

    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
    this.setState({ mounted: true });
  }

  hideBanner =()=> {
    const now = Date.now();
    localStorage.emit('hideHeaderBanner', now);
    this.setState({ showBanner: false });
  }

  renderHeaderBanner = (data = {}) => {
    const { className, activeRoute } = this.props;
    const dataCount = +get(data, 'dataCount', 0);
    const randomData = dataCount ? Math.floor(Math.random() * dataCount) : 0;
    const currentBanner = get(data, `data[${randomData}]`, {});
    const { url, image_big, image_middle, image_small, id } = currentBanner;
    const breakpointDesktop = (this.sensor.getVariable('breakpointDesktop') || 1250) - 1;
    const breakpointTablet = (this.sensor.getVariable('breakpointTablet') || 768) - 1;

    if (!url) return <div />;

    this.counterEntity = { banner_id: id };
    this.counterRendered += 1;
    const lc_id = +get(data, 'data[0].lc_id');
    const dev_id = +get(data, 'data[0].dev_id');

    if (lc_id) this.counterEntity.project_id = lc_id;
    if (dev_id) this.counterEntity.developer_id = dev_id;

    return (
      <>
        {this.counterRendered <=1 &&
          <Counter
            entity={this.counterEntity}
            mode="view"
            pageUrl={get(activeRoute, 'path')}
            action="showHeaderBanner"
          />
        }
        <div {...bemClasses({ extra: className })}>
          <div {...bemClasses('imgContainer')}>
            <Counter
              injectClickName="onClick"
              mode="click"
              entity={this.counterEntity}
              action="openHeaderBanner"
            >
              <Link
                {...bemClasses('link')}
                href={url}
                rel="nofollow"
                external
                blank
                children={<span />}
              />
            </Counter>
            <picture {...bemClasses('picture')}>
              {image_small && <source media={`(max-width: ${breakpointTablet}px)`} srcSet={`/images/orig/full/${image_small}`} />}
              {image_middle && <source media={`(max-width: ${breakpointDesktop}px)`} srcSet={`/images/orig/full/${image_middle}`} />}
              <img {...bemClasses('img')} src={`/images/orig/full/${image_big}`} alt="bannerHeader" loading="lazy" decoding="async" />
            </picture>
            <Button
              {...bemClasses('btn', 'hidden')}
              onClick={this.hideBanner}
              iconType={iconTypes.clear}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { showBanner, mounted } = this.state;
    const { className, geolocation = {}, activeRoute } = this.props;

    if (!showBanner) return null;
    if (!mounted) return <div {...bemClasses({ extra: className })} />;

    const locationTree = get(geolocation, 'location.tree', 1);

    return (
      <>
        {this.counterRendered > 1 &&
          <Counter
            entity={this.counterEntity}
            mode="view"
            pageUrl={get(activeRoute, 'path')}
            action="showHeaderBanner"
          />
        }
        <DataProvider
          url="common/region-banners/header-banner"
          query={{
            count: true,
            parentTree: locationTree,
            project: __PROJECT__
          }}
          queryJson
          cache={cacheTerms.standartInfoProvider}
          renderer={this.renderHeaderBanner}
        />
      </>
    );
  }
}

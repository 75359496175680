/**
 * Created by Vit on 26.05.2017.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import DataProvider from 'api/data-provider';
import simpleStore from 'helpers/simpleStore';
import { subscribe, unsubscribe } from 'helpers/global-events';
import Icon, { iconTypes } from 'components/icon';
import DropDown, { close as closeDD } from 'components/drop-down';
import Button from 'components/button';

import FavoritesData from './favorites-data';

const classes = new Bem('favorites');
const bemClassesDD = new Bem('dropDownList');

export default class FavoritesDropdown extends PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  constructor(...args) {
    super(...args);
    this.onCloseDD = this.onCloseDD.bind(this);
    this.updateCoors = this.updateCoors.bind(this);

    this.ddRef = null;
    subscribe('favoritesIcon', this.updateCoors);
  }

  componentWillUnmount() {
    unsubscribe('favoritesIcon', this.updateCoors);
  }

  onCloseDD() {
    closeDD(this.ddRef);
  }

  updateCoors() {
    simpleStore.set('favoritesIcon', this.node.getBoundingClientRect());
  }

  render() {
    return (
      <DropDown
        {...classes({ extra: this.props.className })}
        ref={ref => this.ddRef = ref}
        ddRef={ref => this.node = ref}
        disableContent
        list
        inForm
        wAuto
      >
        <Button
          {...classes('btn', 'favorites')}
          customRenderer
          title="Избранное"
        >
          <span {...classes('btnContent')}>
            <Icon {...classes('icon')} type={iconTypes.favorites} />
          </span>
        </Button>
        <div {...bemClassesDD('contentContainer')}>
          <div {...bemClassesDD('content')}>
            <DataProvider
              url="domik/favorites/favorites"
              updateEvent="updateFavorites"
            >
              <FavoritesData onCloseDD={this.onCloseDD} />
            </DataProvider>
            <div />
          </div>
        </div>
      </DropDown>
    );
  }
}

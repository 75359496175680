/**
 * Created by PIV on 22.06.2016.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Link from 'components/link';

import Banner from './banner';

import bannerImg from './data/domik_branding_r_3323649.png';

import 'styles/base/components/banners/_aside-banner.scss';

const classes = new Bem('asideBanner');

/**
 * AsideBanner
 * Используется для прилипания банеров слева контента и справа
 * Пример:
 * ```html
 * <AsideBanner left />
 * ```
 */

class AsideBanner extends Component {
  static propTypes = {
    className: PropTypes.string,
    /**
     * Прилепить слева банер
     */
    left: PropTypes.bool,
    /**
     * Прилепить ссправа банер
     */
    right: PropTypes.bool
  };

  static componentCacheConfig = {
    strategy: 'template',
    enable: true
  };

  render() {
    const { left, right, className } = this.props;
    return (
      <div
        {...classes({
          modifiers: { left, right },
          extra: className
        })}
      >
        <Banner {...classes('banner')}>
          <Link
            rel="nofollow"
            blank
            to="/data/go/avtograf.kiev.ua"
          >
            <img
              {...classes('bannerImg')}
              src={bannerImg}
              alt="баннер"
            />
          </Link>
        </Banner>
      </div>
    );
  }
}

export default AsideBanner;

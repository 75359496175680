/* eslint-disable import/prefer-default-export */
import React from 'react';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import localStorage from 'helpers/local-storage';
import DataProvider from 'api/data-provider';

import MapCard from './map-card';
import marker from '../assets/map-marker';

const pointLevelMarkers = {
  NA: 'grayBig',
  lowLevel: 'redBig',
  mediumLevel: 'yellowBig',
  highLevel: 'greenBig',
  readyStatus: 'blueBig'
};

const pointLevelMarkersSmall = {
  NA: 'gray',
  lowLevel: 'red',
  mediumLevel: 'yellow',
  highLevel: 'green',
  readyStatus: 'blue'
};

export function getBigMarkerType(feature) {
  const { properties = {} } = feature;
  const { points = {}, living_complex_build_status } = properties;
  const index = findIndex(living_complex_build_status, { status: 'ready' });
  const markerType = index >= 0 ?
    'blueBig' :
    pointLevelMarkers[points.level || 'NA'];
  return markerType;
}

export function getMarkerType(feature) {
  const { properties = {} } = feature;
  const { pointsLevel, status_ready } = properties;
  const markerType = status_ready ?
    'blue' :
    pointLevelMarkersSmall[pointsLevel || 'NA'];
  return markerType;
}

export function mapTile(payload, viewCard) {
  const { data = [] } = payload;
  if (!data || !data[0]) return null;

  return (
    <MapCard
      clickOutsideMapCard={evt => this.clickOutsideMapCard(evt)}
      changeSizeMap={this.changeSizeMap}
      fullScreenMap={this.state.isFullScreenMode}
      object={data[0]}
      tileCard={viewCard}
      project={this.project}
    />
  );
}

export function sizeMap(isMobileSafari) {
  const { isFullScreenMode, isFullscreenMap } = this.state;

  if (isMobileSafari) {
    this.setState({
      isFullScreenMode: !isFullScreenMode,
      fullMapLegend: !isFullScreenMode
    });
  } else {
    this.setState({
      isFullscreenMap: !isFullscreenMap,
      fullMapLegend: !isFullscreenMap
    });
  }
}

export function mapLegendView() {
  const { fullMapLegend } = this.state;

  if (fullMapLegend) {
    this.closeMapLegendDelay = setTimeout(() => animation.call(this), 1000);
  } else {
    animation.call(this);
  }
  localStorage.emit('fullMapLegend', !fullMapLegend);
  this.setState({ animatedMapLegend: fullMapLegend });
}

function animation() {
  this.setState({ fullMapLegend: !this.state.fullMapLegend, animatedMapLegend: false });
}

export function idsChanged(ids) {
  if (ids && ids[0]) {
    this.setState({ handleMarkerId: ids[0] });
  }
}

export function outsideMapCard(evt) {
  if (evt.target.id !== 'fullscreen' && !evt.target.classList.contains('fullscreen')) {
    this.setState({ handleMarkerId: null });
  }
}

export function mapMarkerRenderer(feature) {
  return marker(getMarkerType.call(null, feature));
}

export function complexMapTile() {
  const { handleMarkerId } = this.state;
  const project = handleMarkerId ? (
    <DataProvider
      url="bild/newbuilds-list"
      query={{ id: handleMarkerId }}
      renderer={this.mapTileRenderer}
    />
  ) : null;

  return project;
}

export function setFullMapLegendState() {
  const { fullMapLegend } = this.state;
  const storageFullMapLegend = localStorage.get('fullMapLegend');

  if (fullMapLegend !== storageFullMapLegend && storageFullMapLegend !== null) {
    this.setState({ fullMapLegend: !fullMapLegend });
  }
}

export function isPhone(data) {
  return get(data, 'media.phone');
}

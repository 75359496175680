import menuTranslates from './menu-i18n.json';

export default function () {
  return {
    ...menuTranslates,

    logoDescription: {
      ru: 'Новостройки Украины',
      ua: 'Новобудови України'
    },

    aboutBild: {
      ru: 'Про Билд-новостройки',
      ua: 'Про Білд-новобудови'
    },
    termsUser: {
      ru: 'Пользовательское соглашение',
      ua: 'Угода користувача'
    },
    linkToRegistrationConditionTitle: {
      ru: 'Условия регистрации',
      ua: 'Умови реєстрації'
    },
    linkToRegistrationConditionLabel: {
      ru: 'Политика обработки персональных данных',
      ua: 'Політика обробки персональних даних'
    },
    tariffPlans: {
      ru: 'Реклама новостроек',
      ua: 'Реклама новобудов'
    },

    telTopic: {
      ru: 'Телефон:\u00a0',
      ua: 'Телефон:\u00a0'
    },

    askQuestionBtn: {
      ru: 'Задать вопрос',
      ua: 'Задати питання'
    },
    askQuestionNotification: {
      ru: 'Ваше сообщение отправлено администратору.\nМы ответим на ваш вопрос в ближайшее время.\nСпасибо!',
      ua: 'Ваше повідомлення відправлено адміністратору.\nМи відповімо на ваше запитання найближчим часом.\nДякуємо!'
    },
    askQuestionModalTopic: {
      ru: 'Напишите нам',
      ua: 'Напишіть нам'
    },

    departmentTopic: {
      ru: 'Работаем:\u00a0',
      ua: 'Працюємо:\u00a0'
    },
    department: {
      ru: 'Пн-Пт с 9:00 до 18:00',
      ua: 'Пн-Пт з 9:00 до 18:00'
    },

    linkToTelegramTitle: {
      ru: 'Перейти в телеграм',
      ua: 'Перейти в телеграм'
    },

    linkToVintageTitle: {
      ru: 'VINTAGE - СОЗДАТЕЛИ ЛУЧШИХ САЙТОВ В МИРЕ',
      ua: 'VINTAGE - ТВОРЦІ КРАЩИХ САЙТІВ В СВІТІ'
    }
  };
}

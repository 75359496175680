/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobileSafari } from 'react-device-detect';
// import isEqual from 'lodash/isEqual';
// import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import localStorage from 'helpers/local-storage';
import beautifyNumber from 'helpers/beautifyNumber';
import { emit } from 'helpers/global-events';
import DataProvider from 'api/data-provider';
import { seoDataShape } from 'api/seo';
import { FavoriteWrapper } from 'subsys/favorites';
import { ComboboxInput, Checkbox } from 'containers/form';
import { Leaflet, Maps, BaseLayersUser, InfoLayer } from 'containers/maps';
import Paginator, { Control, Container, Pages } from 'components/paginator';
import Button, { btnThemes } from 'components/button';
import { iconTypes } from 'components/icon';
import sensor from 'components/sensor';
import ServerRendering from 'components/server-rendering';

import { sizeMap } from 'src-sites/bild/section-projects/common';

// import SearchClarifyBtn from './search-clarify-btn';
import { visInfoLayer } from './map-visualizer';

import translates from './search-i18n.json';

const classesSearch = new Bem('pageSearch');
const classesMapPriceIcon = new Bem('mapPriceIcon');

function mapInfoLayerStyle() {
  return {
    color: '#6a6a6a',
    weight: 1,
    opacity: 0.9,
    fillColor: '#6a6a6a',
    fillOpacity: 0.1,
    sort: 1
  };
}

function buildOrderedQuery(query, orders, order) {
  const orderData = {};
  if (orders && orders[order]) {
    orderData.order = orders[order].param;
  }
  return {
    ...query,
    ...orderData
  };
}

export function buildProviderQuery(ids, bbox, query, orders, order) {
  const q = buildOrderedQuery(query, orders, order);
  return ids ? { ids, ...q } : { bbox, ...q };
}

@sensor
class ViewSearch extends Component {
  static propTypes = {
    // *** Output configuration props
    // Search data provider
    dataProvider: PropTypes.string,
    // Preloaded (from landing) data
    preloadedData: PropTypes.shape({}),
    // How many objects will be display on page
    objectsPerPage: PropTypes.number.isRequired,
    // Current page (for landings with URL-based pagination)
    page: PropTypes.number,
    // URL Builder for URL-based pagination
    pageUrlBuilder: PropTypes.func,
    pageUrlBuilderProps: PropTypes.shape({}),
    // Project name
    project: PropTypes.string,
    // Data provider's query
    query: PropTypes.shape({}),
    // Possible sort orders for this view
    orders: PropTypes.arrayOf(PropTypes.shape({})),
    // Output order
    order: PropTypes.number,
    // Search restriction bounding box
    bbox: PropTypes.shape({}),
    // Object IDs, which will be displayed.
    // Overrides query and bbox restrictions.
    ids: PropTypes.arrayOf(PropTypes.number),
    // Scroll to very top of the page after form is submitted
    scrollToTop: PropTypes.bool,
    // Don't render column with map
    noMapColumn: PropTypes.bool,
    // Renderer for card in left column, when single item on card selected
    cardRenderer: PropTypes.func,
    // Renderer for currency changer in the paginator header
    currenciesChangerRenderer: PropTypes.func,
    // Additional mods for styling for tile card
    cardMods: PropTypes.shape({}),
    // buttons for change view card in for paginator
    viewTypes: PropTypes.arrayOf(PropTypes.shape({})),
    // Prop for paginator container, function, that returns custom blocks after paginator items
    afterItemsContent: PropTypes.func,

    mapSwitchSearchWhenMove: PropTypes.bool,

    // Search form component
    formRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    // Map marker icon renderer
    /** @deprecated */
    // mapMarkerRenderer: PropTypes.func,
    // Map Marker text renderer
    /** @deprecated */
    mapMarkerTextRenderer: PropTypes.func,

    // Type for item markers.
    // * Marker - default marker with custom icon (icon must returned by mapMarkerRenderer)
    // * Text - Text marker. Text must returned by mapMarkerRenderer
    mapMarkerMode: PropTypes.oneOf(['marker', 'text']),

    // Render default map marker for item
    mapMarkerRenderer: PropTypes.func,
    // Render map marker for selected/hovered item
    mapMarkerSelectedRenderer: PropTypes.func,
    // Render central marker icon for geometry (non-point) selected items
    mapCentralMarkerRenderer: PropTypes.func,
    // Position LayerSwitch on Map
    mapLayerSwitchPosition: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['topleft', 'topright', 'bottomleft', 'bottomright'])
    ]),

    // Main layout renderer. All render functions send in "params" argument
    layoutRenderer: PropTypes.func,

    // customSearchHeader
    customSearchHeader: PropTypes.shape({}),

    // *** Interaction props
    // Fires when user change search request
    onQueryChanged: PropTypes.func.isRequired,
    // Fires, when user change search order
    onOrderChanged: PropTypes.func.isRequired,
    // Fires, when user moved/resized map, and map interaction turned on
    onBboxChanged: PropTypes.func.isRequired,
    // Fires, when user selects object/cluster, and only
    // selected objects will be displayed
    onIdsChanged: PropTypes.func.isRequired,

    // SEO data from SEO subsystem (SEO decorator)
    seoData: seoDataShape,
    header: PropTypes.string,
    filterHeaderText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    isFullScreenMode: PropTypes.bool,
    isFullscreenMap: PropTypes.bool
  };

  static defaultProps = {
    // see "orders" prop
    order: 0,
    mapMarkerMode: 'marker',
    mapLayerSwitchPosition: false,
    isFullScreenMode: false
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.renderPaginator = this.renderPaginator.bind(this);
    this.setCardView = this.setCardView.bind(this);
    this.renderMap = this.renderMap.bind(this);
    this.mapBboxChanged = this.mapBboxChanged.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.handleClusterExpand = this.handleClusterExpand.bind(this);
    this.handleClusterCollapse = this.handleClusterCollapse.bind(this);
    this.handleCardHover = this.handleCardHover.bind(this);
    this.handleCardUnhover = this.handleCardUnhover.bind(this);
    this.handleShowOnMap = this.handleShowOnMap.bind(this);
    this.priceMarker = this.priceMarker.bind(this);
    this.priceMarkerHover = this.priceMarkerHover.bind(this);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.bboxTimeout = null;

    this.state = {
      device: 'desktop',
      mapMoveInteraction: true,
      searchBboxChanged: false,
      isFullScreenMode: props.isFullScreenMode,
      viewCardActive: 0
    };

    this.searchBbox = null;
    this.controlId = 'controlId';
    this.showOnMap = null;
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
    this.setLocalViewCardActive();
  }

  componentDidUpdate() {
    if (this.showOnMap) {
      this.handleCardHover(this.showOnMap);
      emit('toggleTileShowOnMapFromMap', this.showOnMap);
    }
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    changeDevice.call(this, desktop, tablet, phone);
  }

  setCardView(index) {
    if (this.state.viewCardActive !== index) {
      this.setState({ viewCardActive: index });

      localStorage.emit('viewCardActive', index);
    }
  }

  setLocalViewCardActive() {
    const localViewCardActive = localStorage.get('viewCardActive');
    const { viewCardActive } = this.state;
    if (localViewCardActive && localViewCardActive !== viewCardActive) {
      this.setState({ viewCardActive: localViewCardActive });
    }
  }

  updateSearchBbox() {
    this.props.onBboxChanged(this.searchBbox);
    this.setState({
      searchBboxChanged: false
    });
  }

  mapBboxChanged(evt) {
    this.searchBbox = evt.target.getBounds().toGeoJSON();
    if (!this.state.mapMoveInteraction) {
      this.setState({
        searchBboxChanged: true
      });
      return;
    }

    clearTimeout(this.bboxTimeout);
    this.bboxTimeout = setTimeout(() => this.updateSearchBbox(), 700);
  }

  _buildQuery(query) {
    return buildOrderedQuery(query, this.props.orders, this.props.order);
  }

  /**
   * Handle map cluster expand
   * @param markers
   */
  handleClusterExpand(markers) {
    this.props.onIdsChanged(map(markers, mrkr => mrkr.feature.properties.id));
  }

  /**
   * Handle marker cluster collapse
   * @param markers
   */
  handleClusterCollapse() {
    this.props.onIdsChanged(null);
  }

  handleMarkerClick(evt) {
    this.props.onIdsChanged([evt.target.feature.properties.id]);
  }

  handleShowOnMap(obj) { // eslint-disable-line
    // TODO implement
  }

  handleCardHover(obj) {
    this.showOnMap = obj;
    if (this.hoverLayerRef && obj) {
      this.hoverLayerRef.setData({
        type: 'Feature',
        properties: {
          ...obj,
          price: `${obj.price.symbol} ${obj.price.mainPriceK}`
        },
        geometry: obj.geometry || obj.geo
      });
    }
  }

  handleCardUnhover() {
    this.showOnMap = null;
    if (this.hoverLayerRef) this.hoverLayerRef.setData(null);
  }

  handleAfterItemsContent = () => {
    if (this.props.afterItemsContent) {
      return this.props.afterItemsContent(this.renderMap);
    }
    return null;
  };

  handleScreenClick = () => {
    sizeMap.call(this, isMobileSafari);
  };

  /**
   * @deprecated
   * Do not delete, maybe it will be needed for implementation component marker in further
   * @param point
   * @param latLng
   * @param cls
   * @return {*}
   */
  drawPriceMarker(point, latLng, cls) {
    const priceIcon = classesMapPriceIcon(null, { hovered: cls === 'hover' }).className;
    const priceIconContent = classesMapPriceIcon('content').className;
    const priceIconText = classesMapPriceIcon('text', 'price').className;
    const priceIconTriangle = classesMapPriceIcon('triangle').className;
    const icon = Leaflet.divIcon({
      className: priceIcon,
      html: `
        <span class="${priceIconContent}">
            <span class="${priceIconTriangle}"></span>
            <span class="${priceIconText}">${this.props.mapMarkerTextRenderer(point)}</span>
        </span>
      `,
      iconSize: [0, 0]
    });

    return Leaflet.marker(latLng, { icon });
  }

  /**
   * @deprecated
   * @param point
   * @param latLng
   * @return {*}
   */
  priceMarker(point, latLng) {
    return this.drawPriceMarker(point, latLng, 'regular');
  }

  /**
   * @deprecated
   * @param point
   * @param latLng
   * @return {*}
   */
  priceMarkerHover(point, latLng) {
    return this.drawPriceMarker(point, latLng, 'hover');
  }

  mapMarker = (point, latLng) => {
    switch (this.props.mapMarkerMode) {
      case 'marker':
        return Leaflet.marker(latLng, { icon: this.props.mapMarkerRenderer(point) });
      default:
        return null;
    }
  };

  mapMarkerSelected = (point, latLng) => { // eslint-disable-line
    return Leaflet.marker(latLng, {
      icon: this.props.mapCentralMarkerRenderer(point),
      zIndexOffset: 10000000
    });
  };

  /**
   * Render map control checkboxes/buttons
   * @return {*}
   */
  renderCheckboxes() {
    return (
      <div {...classesSearch('onMap')}>
        {!this.state.searchBboxChanged && this.props.mapSwitchSearchWhenMove &&
          <Checkbox
            {...classesSearch('checkbox')}
            value={this.state.mapMoveInteraction}
            onChange={checked => this.setState({ mapMoveInteraction: checked })}
            children="Искать когда я перемещаю карту"
            noPadding
            noError
          />
        }
        {this.state.searchBboxChanged && this.props.mapSwitchSearchWhenMove &&
          <Button
            {...classesSearch('btn', 'refresh')}
            onClick={() => this.updateSearchBbox()}
            title="Обновить поиск в этом районе"
            label="Обновить поиск в этом районе"
            iconType={iconTypes.refresh}
            theme={btnThemes.light}
            big
          />
        }
      </div>
    );
  }

  renderMap = (onFeatureClick = true) => {
    const { dataProvider, query, isQuery, bbox = null, mapLayerSwitchPosition } = this.props;

    if (!dataProvider) return;
    const otherInfoLayerProps = {};
    if (onFeatureClick) otherInfoLayerProps.onFeatureClick = this.handleMarkerClick;

    return (
      <div {...classesSearch('mapContainer')}>
        <Maps
          zoom={12}
          maxZoom={22}
          noAutoGeolocate
          onClick={() => this.props.onIdsChanged(null)}
          onZoomAnim={() => clearTimeout(this.bboxTimeout)}
          onMove={() => clearTimeout(this.bboxTimeout)}
          onUserChangeBounds={this.mapBboxChanged}
          bbox={bbox}
          height="calc(100vh - 6.6rem)"
          width="100%"
        >
          <BaseLayersUser positionLayerSwitch={mapLayerSwitchPosition} />
          <DataProvider
            url={`${dataProvider}-map`}
            query={this._buildQuery(query)}
            cache={isQuery ? null : 60}
            queryJson
          >
            <InfoLayer
              isUpdateNeeded={(prevProps, props) =>
                props.dataReloadCount !== prevProps.dataReloadCount
              }
              cluster
              order={1}
              spiderfyDistanceMultiplier={1.5}
              maxClusterRadius={50}
              noAutoFit={!!bbox}
              pointToLayer={this.mapMarker}
              showCoverageOnHover={false}
              onClusterExpand={this.handleClusterExpand}
              onClusterCollapse={this.handleClusterCollapse}
              {...otherInfoLayerProps}
            />
          </DataProvider>
          <InfoLayer
            // featuressd
            pointToLayer={this.mapMarkerSelected}
            order={2}
            noAutoFit
            ref={el => this.hoverLayerRef = el}
            onCentralMarker={feature => feature.geometry.type !== 'Point' ? {
              icon: this.props.mapCentralMarkerRenderer(feature),
              zIndexOffset: 10000
            } : null}
          />
          {query.districts && visInfoLayer(query.districts, null, {
            noAutoFit: true,
            onGetStyle: mapInfoLayerStyle
          })}
          {query.opc && visInfoLayer(query.opc, null, {
            noAutoFit: true,
            onGetStyle: mapInfoLayerStyle
          })}
          {query.landmarks && visInfoLayer(
            query.landmarks,
            query.landmarksDistance || query.$landmarksDistance,
            {
              noAutoFit: true,
              onGetStyle: mapInfoLayerStyle
            }
          )}
          {query.subways && visInfoLayer(
            query.subways,
            query.subwaysDistance || query.$subwaysDistance,
            {
              noAutoFit: true,
              onGetStyle: mapInfoLayerStyle
            })}
          {query.map &&
            <InfoLayer
              noAutoFit
              data={[query.map]}
              onGetStyle={mapInfoLayerStyle}
              pointToLayer={(point, latLng, layerGroup) => {
                const { radius } = point.geometry;
                if (radius) {
                  layerGroup.addLayer(
                    Leaflet.circle(latLng, {
                      radius,
                      ...mapInfoLayerStyle()
                    })
                  );
                }
              }}
            />
          }
        </Maps>
        {this.renderCheckboxes()}
      </div>
    );
  };

  renderForm = (args = {}) => {
    const { query, formRenderer: FormRenderer } = this.props;
    const clientRender =
      <FormRenderer
        {...classesSearch('searchSearchForm')}
        data={query}
        onSearch={this.props.onQueryChanged}
        wRef={el => this.searchForm = el}
        device={this.state.device}
        {...args}
      />;
    const serverRenderer = <div {...classesSearch('searchSearchForm', null, 'searchForm')} />;

    return (
      <ServerRendering
        serverRenderer={serverRenderer}
        clientRenderer={clientRender}
      />
    );
  };

  renderPaginator = () => {
    const { device, viewCardActive } = this.state;
    const {
      dataProvider, preloadedData, query, bbox = null, ids = null,
      cardMods = {}, viewTypes,
      scrollToTop, onOrderChanged, orders, order,
      project, seoData, objectsPerPage,
      page, pageUrlBuilder, pageUrlBuilderProps,
      customSearchHeader, header, filterHeaderText,
      paginatorUpdateEvent, currenciesChangerRenderer
    } = this.props;

    const TileRenderer = get(viewTypes, `[${viewCardActive}].component`);
    const notDesktop = this.state.device !== 'desktop';

    const pagesProps = {};
    if (pageUrlBuilder) {
      pagesProps.linkBuilder = pageUrlBuilder;
      pagesProps.linkBuilderProps = pageUrlBuilderProps;
    }

    let paginatiorProps = {
      url: dataProvider,
      query: buildProviderQuery(ids, bbox, query, orders, order),
      queryJson: true
    };
    if (preloadedData) {
      paginatiorProps = {
        preloaded: preloadedData,
        preloadedStatic: true
      };
    }
    if (paginatorUpdateEvent) {
      paginatiorProps.updateEvent = paginatorUpdateEvent;
    }

    // Render standart paginator
    return (
      <div {...classesSearch('searchContent')} data-test="noSearch">
        {dataProvider &&
          <>
            {/* <SearchClarifyBtn getCoorsKey="searchControlTopPos" /> */}
            <FavoriteWrapper project={project}>
              {({ data = {} }) => (
                <Paginator
                  {...paginatiorProps}
                  changeUrl
                  limit={objectsPerPage}
                  page={page}
                  scrollToControll={notDesktop || (!notDesktop && !scrollToTop)}
                  scrollToTop={!notDesktop && scrollToTop}
                  scrollRestoreKey="testScrollSave"
                >
                  <Control calcCoorsKey="searchControlTopPos">
                    <SearchHeader
                      orders={orders}
                      onOrderChanged={onOrderChanged}
                      currenciesChangerRenderer={currenciesChangerRenderer}
                      defaultOrder={order}
                      seoData={seoData}
                      header={header}
                      filterHeaderText={Object.keys(pageUrlBuilderProps.query).length !== 0}
                      viewCardActive={viewCardActive}
                      viewTypes={viewTypes}
                      setCardView={this.setCardView}
                      labelForOrder={customSearchHeader.labelForOrder}
                      iconForOrder={customSearchHeader.iconForOrder}
                      otherContent={customSearchHeader.otherContent}
                      // renderCheckboxes={this.renderCheckboxes()}
                    />
                  </Control>
                  <Container
                    {...classesSearch('projects')}
                    injectPropName="object"
                    afterItemsContent={this.handleAfterItemsContent}
                  >
                    <TileRenderer
                      onShowOnMap={this.handleShowOnMap}
                      onHover={this.handleCardHover}
                      onUnhover={this.handleCardUnhover}
                      drawMarkerOnPhone={this.priceMarkerHover}
                      device={device}
                      dataMods={{ fill: true, ...cardMods }}
                      favData={data}
                    />
                  </Container>
                  <Pages {...pagesProps} />
                </Paginator>
              )}
            </FavoriteWrapper>
          </>
        }
      </div>
    );
  };

  render() {
    return this.props.layoutRenderer({
      paginator: this.renderPaginator,
      map: this.renderMap,
      form: this.renderForm
    });

    /* const noMapColumn =
    this.props.noMapColumn || (!this.props.noMapColumn && this.state.device === 'phone');
    return (
      <div {...classesSearch()}>
        <TwoColumnsView
          renderLeftColumn={this.renderPaginator}
          renderRightColumn={noMapColumn ? null : this.renderMap}
        />
      </div>
    );
    */
  }
}

@I18nHoc(translates)
class SearchHeader extends PureComponent {
  static propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape({})),
    paginatorCount: PropTypes.number,
    // renderOrder: PropTypes.node,
    onOrderChanged: PropTypes.func,
    defaultOrder: PropTypes.number,
    labelForOrder: PropTypes.string,
    iconForOrder: PropTypes.string,
    header: PropTypes.string,
    filterHeaderText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    viewTypes: PropTypes.arrayOf(PropTypes.shape({})),
    setCardView: PropTypes.func,
    currenciesChangerRenderer: PropTypes.func,
    viewCardActive: PropTypes.number,
    seoData: seoDataShape,
    otherContent: PropTypes.shape({})
    // renderCheckboxes: PropTypes.node
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {};
  }

  static getDerivedStateFromProps(props) {
    return { sortVal: props.defaultOrder };
  }

  render() {
    const { i18n } = this;
    const {
      orders, seoData = {}, paginatorCount = null,
      iconForOrder, labelForOrder, viewTypes, setCardView, viewCardActive,
      header, otherContent, filterHeaderText, currenciesChangerRenderer
    } = this.props;
    let topic = filterHeaderText && i18n('searchResultsText');
    if (!topic && seoData && seoData.header) topic = seoData.header;
    if (!topic) topic = header;
    const btnTitle = {
      tile: i18n('viewTile'),
      list: i18n('viewList')
    };

    const renderChangeViewCard = viewTypes && viewTypes.length > 1 ? (
      <div {...classesSearch('btnsView')}>
        {map(viewTypes, (item, key) => (
          <Button
            key={key}
            {...classesSearch('btn', { view: true, active: key === viewCardActive })}
            onClick={() => setCardView(key)}
            //view={item.view}
            title={get(btnTitle, [item.view])}
            {...item.btnProps}
          />
        ))}
      </div>
    ) : null;

    return (
      <div {...classesSearch('searchHeader')}>
        <div {...classesSearch('text', 'h1')}>
          <h1 children={topic} />
        </div>
        {paginatorCount &&
          <div {...classesSearch('text', 'found')} data-test="paginatorCount">
            <span {...classesSearch('text', 'foundText')} children={`${i18n('foundText')}:\u00a0`} />
            <span {...classesSearch('text', 'foundCount')} children={beautifyNumber(paginatorCount)} />
          </div>
        }
        {currenciesChangerRenderer()}
        {orders &&
          <ComboboxInput
            {...classesSearch('dropdown')}
            listBoxItems={orders}
            onChange={(sortVal) => {
              this.props.onOrderChanged(sortVal);
              this.setState({ sortVal });
            }}
            iconSecondType={iconForOrder && iconForOrder}
            value={this.state.sortVal}
            schema={{
              label: typeof labelForOrder === 'string' ? labelForOrder : i18n('ordersLabel')
            }}
            nullValueItem={false}
            modalContentNoPadding
            dictDynamic
            wAuto
            noError
          />
        }
        {/* {this.props.renderOrder} */}
        {renderChangeViewCard}
        {otherContent}
        {/* <div>{this.props.renderCheckboxes}</div> */}
      </div>
    );
  }
}

export default ViewSearch;

export function changeDevice(desktop, tablet, phone) {
  const { device } = this.state;
  if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
  if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
  if (phone && device !== 'phone') return this.setState({ device: 'phone' });
}

/**
 * Created by Vit on 29.05.2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import limitMax from 'helpers/limitMaxCount';
import Button from 'components/button';
import Icon, { iconTypes } from 'components/icon';

const classes = new Bem('menuTopBtn');

class MenuTopBtn extends Component {
  static propTypes = {
    className: PropTypes.string,
    onOpenSideMenu: PropTypes.func.isRequired,
    notificationCount: PropTypes.number,
    otherPropsIcon: PropTypes.shape({}),
    text: PropTypes.string
  };

  shouldComponentUpdate() {
    return false;
  }

  renderIcon() {
    const { otherPropsIcon = {} } = this.props;

    return (
      <Icon
        {...classes('icon')}
        type={iconTypes.menu}
        {...otherPropsIcon}
      />
    );
  }

  renderText() {
    const { text } = this.props;

    if (!text) return null;

    return (
      <span {...classes('text')} children={text} />
    );
  }

  render() {
    const { className, notificationCount } = this.props;

    return (
      <div {...classes({ extra: className })}>
        <Button
          {...classes('btn', { menu: true, active: false })}
          onClick={() => this.props.onOpenSideMenu(false)}
          customRenderer
          data-test="btnMenu"
        >
          <>
            <span {...classes('btnContent')}>
              {this.renderIcon()}
              {this.renderText()}
            </span>
            {notificationCount > 0 && <span {...classes('text')} children={limitMax(notificationCount)} />}
          </>
        </Button>
      </div>
    );
  }
}
export default MenuTopBtn;

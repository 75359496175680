/**
 * Created by Vit on 29.05.2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';

const classes = new Bem('postAds');

class PostAds extends Component {
  static propTypes = {
    className: PropTypes.string,
    asLinkNoIcon: PropTypes.bool,
    builder: PropTypes.func,
    builderProps: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string
    })
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { className, builder, builderProps = {}, asLinkNoIcon } = this.props;

    return (
      <div {...classes({ extra: className })} data-test="postAds">
        <Link
          {...classes('btn', {
            postAds: true, userMenu: asLinkNoIcon
          })}
          builder={builder}
          builderProps={builderProps}
          title={builderProps.title}
        >
          <span {...classes('btnContent')}>
            {!asLinkNoIcon &&
              <Icon {...classes('icon')} type={iconTypes.addCircle} />
            }
            <span {...classes('text')}>{asLinkNoIcon ? builderProps.title : builderProps.name}</span>
          </span>
        </Link>
      </div>
    );
  }
}

export default PostAds;

import loadable from '@loadable/component';
import React, { Component } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc, LangContext } from 'helpers/i18n';
import appMessage from 'helpers/appMessage';
import preload from 'api/data-preload';
import DataProvider from 'api/data-provider';
import GeoLandings from 'subsys/geo-landings';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import ScrollTopBtn from 'components/scroll-top-btn';
import { BannerAffix, BannerHeader } from 'components/banners';
import { checkUserLang } from 'components/lang-switch';

import HelmetI18n from './helmet-i18n';
import Header from './header/header';
import BildLowerMenu from './lower-menu/lower-menu-content';
import Footer from './footer';

import config from '../../../config';

import translates from './body-i18n.json';

// *** Deferred-load components
const LoginModal = loadable(() => import(/* webpackChunkName: "body-loginModal" */ 'components/login-modal'));
const ManagementToolbar = loadable(() => import(/* webpackChunkName: "body-managementToolbar" */ 'subsys/management-toolbar'));

import 'styles/bild/body/_body.scss';

const bemClasses = new Bem('body');

@preload({
  mainMenuBase: [DataProvider.preload, {
    url: 'core/menu',
    cache: 60 * 24,
    query: {
      project: 'bild'
    }
  }]
}, { noReload: true })
// TODO Debug and refactor this @geolocationConnect()
@I18nHoc(translates)
export default class BildBody extends Component {
  static propTypes = {
    router: PropTypes.shape({}),
    location: PropTypes.shape({}),
    routes: PropTypes.arrayOf(PropTypes.shape({})),
    mainMenuBase: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    children: PropTypes.node
  };

  static selectors = ['html', 'body'];

  componentDidMount() {
    if (__APP__) appMessage({ render: 'done' });
    checkUserLang.call(this);
  }

  render() {
    const { i18n } = this;
    const { routes = [], mainMenuBase, children, location, params = {} } = this.props;
    const activeRoute = routes[routes.length - 1];
    activeRoute['query'] = get(this, 'props.router.location.query');
    activeRoute['page'] = get(this, 'props.router.params.page');
    const onlySocialAuth = config.env !== 'local';

    return (
      <LangContext activeRoute={activeRoute}>
        <Row {...bemClasses(null, activeRoute.mods)} allColumn noMaxW>
          <Col {...bemClasses('content')} allFlex allPr={0}>
            <LazyHydrate whenIdle>
              <HelmetI18n location={location} />
            </LazyHydrate>
            <Row allColumn hFull noMaxW>
              <Col {...bemClasses('mainContainer')} allFlex allPr={0}>
                <div {...bemClasses('headerContainer')}>
                  {!activeRoute.noHeaderBanner &&
                    <GeoLandings
                      base={{}}
                    >
                      <BannerHeader activeRoute={activeRoute}/>
                    </GeoLandings>
                  }
                  <GeoLandings
                    injectPropName="mainMenu"
                    base={mainMenuBase}
                  >
                    <Header activeRoute={activeRoute} landing={params ? params.landing : null} />
                  </GeoLandings>
                </div>
                <Container {...bemClasses('main')} body>
                  {React.Children.map(children, child =>
                    React.cloneElement(child, { ...child.props, mainMenuBase })
                  )}
                </Container>
                {!activeRoute.noLowerMenu &&
                  <GeoLandings
                    injectPropName="mainMenu"
                    base={mainMenuBase}
                  >
                    <BildLowerMenu activeRoute={activeRoute} />
                  </GeoLandings>
                }
              </Col>
            </Row>

            <LazyHydrate whenIdle>
              <ScrollTopBtn {...bemClasses('scrollTopBtn')} />
              <GeoLandings
                base={{}}
              >
                <BannerAffix activeRoute={activeRoute} />
              </GeoLandings>
            </LazyHydrate>

            <LazyHydrate whenVisible>
              {!activeRoute.noFooter &&
                <GeoLandings
                  injectPropName="mainMenu"
                  base={mainMenuBase}
                >
                  <Footer
                    {...bemClasses('footer')}
                    location={location}
                    activeRoute={activeRoute}
                  />
                </GeoLandings>
              }
            </LazyHydrate>

            <LazyHydrate whenIdle>
              <LoginModal onlySocialAuth={onlySocialAuth} />
              <ManagementToolbar />
            </LazyHydrate>
            <noscript {...bemClasses('noJS')} children={i18n('noscript')} />
          </Col>
        </Row>
      </LangContext>
    );
  }
}

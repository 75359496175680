import React, { Component } from 'react';
import Bem from 'bemHelper';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { FavoriteWrapper } from 'subsys/favorites';

const classesMap = new Bem('mapProjects');

@onClickOutside
export default class MapCard extends Component {
  static propTypes = {
    clickOutsideMapCard: PropTypes.func,
    changeSizeMap: PropTypes.func,
    fullScreenMap: PropTypes.bool,
    object: PropTypes.shape({}),
    tileCard: PropTypes.func,
    project: PropTypes.string
  };

  handleClickOutside = (evt) => {
    this.props.clickOutsideMapCard(evt);
  };

  renderMapCard = (favData = {}) => {
    const { object, tileCard: TileCard } = this.props;

    return (
      <div {...classesMap('containerCardAndSizeMap')}>
        <TileCard
          object={object}
          favData={favData.data}
        />
      </div>
    );
  };

  render() {
    const { project, entity } = this.props;

    return (
      <FavoriteWrapper project={project} entity={entity} >
        {favData => this.renderMapCard(favData)}
      </FavoriteWrapper>
    );
  }
}

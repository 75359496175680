/**
 * Created by Kotkin on 05.07.2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import get from 'lodash/get';
import { I18nHoc } from 'helpers/i18n';
import { UserProp } from 'auth';
import Form, { /*ImageInput*/ VisFunc } from 'containers/form';
import Button from 'components/button';
import { iconTypes } from 'components/icon';
import { Thumb } from 'components/image';

import translates from './menu-i18n.json';

import 'styles/base/components/header/side-menu/_user-menu.scss';

const classes = new Bem('userMenu');

@I18nHoc(translates)
export default class UserMenu extends Component {
  static propTypes = {
    className: PropTypes.string,
    userData: UserProp,
    onCloseSideMenu: PropTypes.func,
    desktop: PropTypes.bool,
    childrenInfo: PropTypes.node,
    children: PropTypes.node
  };

  constructor(...props) {
    super(...props);
    this.onHandleClick = this.onHandleClick.bind(this);
  }

  onHandleClick(logout = null) {
    if (logout) logout();

    this.props.onCloseSideMenu();
  }

  renderAvatar(val) {
    const avatar = get(val, 'avatar.filename');

    return (
      <div {...classes('photo')}>
        <div {...classes('imgContainer')}>
          <div {...classes('img')}>
            <Thumb
              {...classes('imgPic')}
              image={{ id: avatar }}
              tagSpan
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { i18n } = this;
    const {
      userData = {},
      desktop, onCloseSideMenu,
      className, childrenInfo, children
    } = this.props;
    const { id, name, forum_nick } = userData;

    return (
      <div {...classes({ extra: className })}>
        {id &&
          <div {...classes('info')}>
            <div {...classes('infoLeft')}>
              <Form
                {...classes('form')}
                controller="user/domik/image-user"
                entityId={id}
                updateEvent="userProfileUpdated"
                nested
                ref={ref => this.submitAct = ref}
                showPopupValidationMessages={false}
              >
                <VisFunc
                  dataVisualize={['avatar']}
                  func={this.renderAvatar}
                />
                {/*
                <ImageInput
                  {...classes('photo')}
                  dataBind="avatar"
                  imageSize={false}
                  dataView="avaForMenu"
                  circled
                  onChange={(val) => {
                    if (val === null || val.filename) {
                      this.submitAct.handleSubmit();
                    }
                  }}
                />
                */}
              </Form>
              {id &&
                <div {...classes('idUser')}>
                  <span {...classes('text')} children={`ID: ${id}`} />
                </div>
              }
            </div>
            <div {...classes('infoRight')}>
              {name &&
                <div {...classes('name')}>
                  <span {...classes('text')} children={name} />
                </div>
              }
              {/*
              <div {...classes('own')}>
                <span {...classes('text')}>Владелец</span>
              </div>
              */}
              {forum_nick &&
                <div {...classes('nickName')}>
                  <span {...classes('text')} children={`${i18n('forumNickText')}: ${forum_nick}`} />
                </div>
              }
            </div>
            {childrenInfo}
          </div>
        }
        {children}
        {desktop &&
          <Button
            {...classes('btn', 'closeMenu')}
            onClick={onCloseSideMenu}
            iconType={iconTypes.clear}
            title={i18n('btnClose')}
          />
        }
      </div>
    );
  }
}

/**
 * Created by Vit on 30.05.2017.
 */
import React, { PureComponent } from 'react';
import { routerShape } from 'react-router';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import Bem from 'bemHelper';
import { Input } from 'containers/form';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import 'styles/base/components/header/_search-input.scss';

const classes = new Bem('searchInput');

@onClickOutside
export default class SearchInput extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    toggleSearch: PropTypes.func,
    header: PropTypes.bool,
    menu: PropTypes.bool,
    active: PropTypes.bool,
    desktop: PropTypes.bool,
    phone: PropTypes.bool,
    router: routerShape,
    params: PropTypes.shape({
      search: PropTypes.string
    }),
    linkBuilder: PropTypes.func,
    builderProps: PropTypes.shape({}),
    queryParams: PropTypes.shape({}),
    queryParamName: PropTypes.string,
    placeholder: PropTypes.string,
    onCounterClick: PropTypes.func,
    noInteractive: PropTypes.bool,
    otherProps: PropTypes.shape({
      btnSearch: PropTypes.shape({})
    }),
    onSearch: PropTypes.func
  };

  static defaultProps = {
    placeholder: 'Текстовый поиск по сайту'
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.onSearchClickHandle = this.onSearchClickHandle.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    const { params = {} } = props;

    this.state = {
      value: params.search || props.search || null
    };
  }

  onSearchClickHandle() {
    if (this.props.phone || this.props.noInteractive) return;

    if (this.props.toggleSearch) this.props.toggleSearch();
    if (this.props.onSearch) this.props.onSearch(this.state.value);
  }

  onSubmit(event) {
    event.preventDefault();
    const { linkBuilder, builderProps = {}, queryParams,
      queryParamName, onCounterClick } = this.props;
    const search = this.state.value;

    if (onCounterClick) onCounterClick();
    if (this.props.onSearch) this.props.onSearch(this.state.value);

    if (linkBuilder && search) {
      const link = linkBuilder({search, ...builderProps});
      const query = { ...queryParams };
      if (queryParamName) {
        query[queryParamName] = search;
      }
      this.props.router.push({ pathname: link.href, query });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search && this.props.search !== this.state.value) {
      this.setState({value: this.props.search});
    }
  }

  onInputChange(value) {
    if (this.props.onSearchChange) this.props.onSearchChange(value)
    this.setState({ value });
  }

  handleClickOutside() {
    if (this.props.active) return this.props.toggleSearch();
  }

  render() {
    // if (__AMP__) return null;
    const {
      phone, active, header, noInteractive, toggleSearch, menu, className, placeholder, otherProps = {}
    } = this.props;
    const { btnSearch = {} } = otherProps;

    return (
      <div {...classes(null, { opened: active, header, noInteractive, menu }, className)}>
        <form
          {...classes('form')}
          onSubmit={this.onSubmit}
        >
          <Button
            {...classes('btn', 'search')}
            type="submit"
            onClick={this.onSearchClickHandle}
            iconType={iconTypes.search}
            title={placeholder || 'Поиск по сайту'}
            {...btnSearch}
          />
          <Input
            {...classes('input')}
            type="search"
            schema={{
              placeholder: !phone ? placeholder : 'Поиск по сайту'
            }}
            value={this.state.value}
            onChange={this.onInputChange}
            clearInputCallBack={toggleSearch}
            autoFocus={active}
            noError
          />
        </form>
      </div>
    );
  }
}

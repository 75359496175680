import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'api/data-provider';
import isEqual from 'lodash/isEqual';
import {
  Leaflet,
  Maps,
  GoogleLayer,
  InfoLayer,
  MeasureControl,
  LocateBtn,
  marker
} from 'containers/maps';

const styleDistrict = {
  color: '#E00000',
  weight: 4,
  opacity: 0.8,
  fillColor: '#E00000',
  fillOpacity: 0.1,
  sort: 1
};

export const styleToponym = {
  color: '#00BA09',
  weight: 2,
  opacity: 0.8,
  fillColor: '#00BA09',
  fillOpacity: 0.1,
  sort: 2
};

const typeStyles = {
  city_district: styleDistrict,
  toponym: styleToponym,
  microdistrict: styleToponym
};

export function visPointAreas(point, latLng, layerGroup, distance) { // eslint-disable-line

}

export function visInfoLayer(ids, distance, layerProps = {}) {
  if (!ids || ids.length === 0) {
    return null;
  }

  return (
    <DataProvider
      url="domik/location/districts-geo"
      queryJson
      query={{ ids }}
    >
      <InfoLayer
        labelField="display_name"
        typeStyleProp="type"
        typeStyles={typeStyles}
        distance={distance}
        isUpdateNeeded={(uPrev, uNew) => (
          !isEqual(uPrev.data, uNew.data) || !isEqual(uPrev.distance, uNew.distance)
        )}
        pointToLayer={(point, latLng, layerGroup) => {
          if (distance) {
            const style = layerProps.onGetStyle ? layerProps.onGetStyle() : {};
            layerGroup.addLayer(
              Leaflet.circle(latLng, {
                radius: distance,
                ...style
              }));
          } else {
            return Leaflet.marker(latLng, {
              icon: marker('standart')
            });
          }
        }}
        interactive={false}
        {...layerProps}
      />
    </DataProvider>
  );
}

class MapsVisualizer extends Component {
  static propTypes = {
    className: PropTypes.string,
    locationsField: PropTypes.string,
    distanceField: PropTypes.string,
    values: PropTypes.shape({}),
    bbox: PropTypes.shape({})
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.values, nextProps.values);
  }

  render() {
    const ids = this.props.values[this.props.locationsField];
    const { className } = this.props;
    const { distanceField } = this.props;

    let distance = null;
    if (distanceField && this.props.values[distanceField]) {
      distance = this.props.values[distanceField];
    }

    return (
      <div className={className ? `mapsVisualizer ${className}` : 'mapsVisualizer'}>
        <Maps
          zoom={12}
          bbox={this.props.bbox}
          // height="60rem"
          // width="60rem"
        >
          <GoogleLayer key="gl" />
          <MeasureControl key="measure" />
          <LocateBtn key="locate" />
          {visInfoLayer(ids, distance)}
        </Maps>
      </div>
    );
  }
}

export default MapsVisualizer;

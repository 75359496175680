import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import Bem from 'bemHelper';
import cacheTerms from 'helpers/cacheTerms';
import localStorage from 'helpers/local-storage';
import DataProvider from 'api/data-provider';
import Counter from 'subsys/counter';
import Link from 'components/link';
import sensor from 'components/sensor';
import { Thumb } from 'components/image';
import Button from 'components/button';
import { iconTypes } from 'components/icon';

import 'styles/base/components/banners/_banner-affix.scss';

const bemClasses = new Bem('bannerAffix');

const oneDay = 1000 * 60 * 60 * 24;

@sensor
export default class BannerAffix extends Component {
  static propTypes = {
    className: PropTypes.string,
    geolocation: PropTypes.shape({}),
    activeRoute: PropTypes.shape({})
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onHandleResize;
    this.counterEntity = null;
    this.providerCounted = false;

    let showBanner = true;
    const hideSideBanner = localStorage.get('hideSideBanner');
    const dateDifference = (Date.now() - new Date(hideSideBanner)) / oneDay;
    if (hideSideBanner && dateDifference < 5) showBanner = false

    this.state = {
      showBanner,
      device: 'phone',
      banOnTop: false,
      needCount: true,
      mounted: false
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
    this.setState({ mounted: true });
  }

  componentDidUpdate() {
    const { needCount } = this.state;
    if (needCount && this.providerCounted) this.setState({ needCount: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return get(this, 'props.activeRoute.path') !== get(nextProps, 'activeRoute.path') ||
      this.state.showBanner !== nextState.showBanner ||
      this.state.banOnTop !== nextState.banOnTop
      ;
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;

    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  onScroll(data) {
    const { device, banOnTop } = this.state;

    if (device !== 'phone') return null;

    const heightHeaderPhone = toNumber(this.sensor.getVariable('heightHeaderPhonePx'));
    const viewPortHeight = get(data, 'viewPortHeight');
    const viewPortWidth = get(data, 'viewPortWidth');
    const scrollTop = get(data, 'scrollTop');
    const banRef = this.banRef ? this.banRef.getBoundingClientRect() : null;
    const banTop = get(banRef, 'top');
    const fromTop = viewPortHeight > viewPortWidth ? 110 : 10;

    if (banTop <= fromTop + heightHeaderPhone && !banOnTop) {
      this.setState({ banOnTop: true });
    }
    if (scrollTop < viewPortHeight && banOnTop) {
      this.setState({ banOnTop: false });
    }
  }

  hideBanner =()=> {
    const now = Date.now();
    localStorage.emit('hideSideBanner', now);
    this.setState({ showBanner: false });
  }

  renderBannerAffix =(data = {})=> {
    const { device, banOnTop, needCount, mounted } = this.state;

    if (!mounted) return <div />;

    const { className, activeRoute } = this.props;
    const isDesktop = device === 'desktop';
    const dataCount = +get(data, 'dataCount', 0);
    const randomData = dataCount ? Math.floor(Math.random() * dataCount) : 0;
    const currentBanner = get(data, `data[${randomData}]`, {});
    const { url, image_big, image_small, id } = currentBanner;

    if (!url || !image_big || !image_small) return <div />;

    this.counterEntity = { banner_id: id };
    const lc_id = +get(data, 'data[0].lc_id');
    const dev_id = +get(data, 'data[0].dev_id');
    if (lc_id) this.counterEntity.project_id = lc_id;
    if (dev_id) this.counterEntity.developer_id = dev_id;
    this.providerCounted = true;

    return (
      <>
        {needCount &&
          <Counter
            entity={this.counterEntity}
            mode="view"
            action="showSmallAffixBanner"
            pageUrl={get(activeRoute, 'path')}
          />
        }
        <Counter
          injectClickName={isDesktop ? 'onMouseOver' : 'onClick'}
          mode="click"
          entity={this.counterEntity}
          action="showBigAffixBanner"
          doNotInjectCountAction
        >
          <div
            {...bemClasses({ extra: className, modifiers: { fixed: banOnTop } })}
            ref={el => this.banRef = el}
          >
            <Counter
              injectClickName="onClick"
              mode="click"
              entity={this.counterEntity}
              action="openLinkAffixBanner"
            >
              <Link
                {...bemClasses('link')}
                href={url}
                rel="nofollow"
                external
                blank
                children={<span />}
              />
            </Counter>
            <div {...bemClasses('imgContainer')}>
              <div {...bemClasses('img', 'big')}>
                <Thumb
                  {...bemClasses('imgPic')}
                  image={{ id: image_big }}
                />
              </div>
              <div {...bemClasses('img', 'small')}>
                <Thumb
                  {...bemClasses('imgPic')}
                  image={{ id: image_small }}
                />
              </div>
            </div>
            <Button
              {...bemClasses('btn', 'hidden')}
              onClick={this.hideBanner}
              iconType={iconTypes.clear}
            />
          </div>
        </Counter>
      </>
    );
  }

  render() {
    const { showBanner } = this.state;

    if (!showBanner) return null;

    const { geolocation = {}, activeRoute } = this.props;
    const locationTree = get(geolocation, 'location.tree', 1);

    return (
      <>
        {!!this.counterEntity &&
          <Counter
            entity={{
              ...this.counterEntity,
              render: true
            }}
            mode="view"
            action="showSmallAffixBanner"
            pageUrl={get(activeRoute, 'path')}
          />
        }
        <DataProvider
          url="common/region-banners/side-banner"
          query={{
            count: true,
            parentTree: locationTree,
            project: __PROJECT__
          }}
          queryJson
          cache={cacheTerms.standartInfoProvider}
          renderer={this.renderBannerAffix}
        />
      </>
    );
  }
}

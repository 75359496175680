import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { routerShape } from 'react-router/lib/PropTypes';
import map from 'lodash/map';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { langInfo } from 'helpers/i18n';
import localStorage from 'helpers/local-storage';
import Link from 'components/link';

import 'styles/base/components/lang-switch/_switch.scss';

const classes = new Bem('langSwitch');

export default class Switch extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    activeRoute: PropTypes.shape({
      lang: PropTypes.string,
      langs: PropTypes.shape({})
    }),
    switchWithoutPages: PropTypes.bool
  };

  static defaultProps = {
    switchWithoutPages: false
  };

  static contextTypes = {
    router: routerShape
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.router = ctx.router;
  }

  componentDidUpdate() {
    const lang = get(this, 'props.activeRoute.lang');

    if (lang) localStorage.emit('lang', lang);
  }

  renderLangs(activeRoute) {
    const { lang, langs, query, page } = activeRoute;
    const { params } = this.router;

    return map(langInfo, (val, key) => {
      let href = get(langs, `[${key}]`, '');
      href = href.replace(':project', __PROJECT__);
      if (!this.props.switchWithoutPages && page) href += `/page-${page}`;
      if (key !== lang) {
        return (
          <Link
            key={key}
            {...classes('link', { [key]: true })}
            href={parseRoute(href, params)}
            query={query}
            title={val.nameLong}
          >
            <span {...classes('linkContent')}>
              <span
                {...classes('text')}
                children={val.nameLong}
              />
            </span>
          </Link>
        );
      }
    });
  }

  render() {
    const { className, activeRoute } = this.props;
    const { lang, langs } = activeRoute;

    if (!lang || !langs) return null;

    return (
      <div
        {...classes({ extra: className })}
        children={this.renderLangs(activeRoute)}
      />
    );
  }
}

function parseRoute(route, params) {
  let newRoute = route;
  if (params && newRoute) {
    map(params, (val, key) => newRoute = newRoute.replace(`:${key}`, val));
  }
  return newRoute;
}

export function checkUserLang() {
  const storageLang = localStorage.get('lang') || 'ua';
  const { routes = [], router, params } = this.props;
  const activeRoute = routes[routes.length - 1];
  const { lang: urlLang, langs } = activeRoute;
  if (storageLang && storageLang !== urlLang && langs)
    router.push(parseRoute(langs[storageLang], params));
}

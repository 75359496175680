import React, { Component } from 'react';
import { routerShape } from 'react-router';
import { geolocationConnect, GeolocationProp } from 'geolocation';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import replace from 'lodash/replace';
import split from 'lodash/split';
import Bem from 'bemHelper';
import { AutocompleteInput } from 'containers/form';
import Icon, { iconTypes } from 'components/icon';
import { Thumb } from 'components/image';

const classes = new Bem('searchInput');
const bemClassesItemBtn = new Bem('buttonAutocomplete');
const bemClassesGroup = new Bem('groupAutocomplete');

@geolocationConnect()
export default class SearchBox extends Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    iconPlaceholder: PropTypes.string,
    onItemSelected: PropTypes.func,
    linkBuilder: PropTypes.func,
    linkBuilderProps: PropTypes.shape({}),
    queryParams: PropTypes.shape({}),
    searchBoxContent: PropTypes.shape({}),
    desktop: PropTypes.bool,
    noError: PropTypes.bool,
    clearOnSelect: PropTypes.bool
  };

  static defaultProps = {
    placeholder: 'Текстовый поиск по сайту',
    queryParams: {}
  };

  static contextTypes = {
    router: routerShape
  };

  static itemRender(autocompleteItem) {
    const mods = replace(get(autocompleteItem, 'icon'), ',' || ', ', ' ');
    const iconType = get(split(mods, ' '), '[0]');

    return (
      <span {...bemClassesItemBtn('btnContent', 'withAva', 'btn__content')} data-test="btnContent">
        {iconType ?
          <span {...bemClassesItemBtn('avatarIcon', mods, 'btn__icon')}>
            <Icon {...bemClassesItemBtn('icon')} type={iconTypes[iconType]} />
          </span> :
          <Thumb
            {...bemClassesItemBtn('avatar', null, 'btn__icon')}
            image={autocompleteItem.image || {}}
            tagSpan
          />
        }
        <span {...bemClassesItemBtn('textContainer')}>
          <span {...bemClassesItemBtn('text', null, 'btn__text')} data-test="btnText" children={autocompleteItem.name} />
          {autocompleteItem.uniq_count > 1 &&
            <span {...bemClassesItemBtn('text', 'locality', 'btn__text')} children={autocompleteItem.locality_name} />
          }
        </span>
      </span>
    );
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      searchValue: null,
      autoFocus: false
    };
    this.router = ctx.router;
    this.autocomplete = null;
    this.selectedItem = null;
  }

  componentDidMount() {
    this.unlisten = this.router.listen((location) => {
      if (
        this.autocomplete &&
        this.selectedItem &&
        location.pathname.indexOf(this.selectedItem.url) === -1
      ) {
        this.autocomplete.resetAllData();
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  toggleFocus = () => {
    this.setState({ autoFocus: !this.state.autoFocus });
  };

  handleChange(searchValue, selectedItem) {
    if (searchValue !== this.state.searchValue) {
      this.selectedItem = selectedItem || null;
      this.setState({ searchValue });
    }
  }

  handleSelect(item) {
    if (this.props.onItemSelected) this.props.onItemSelected(item);
    if (this.props.clearOnSelect) {
      this.setState({ searchValue: '' });
    }
    if (this.props.linkBuilder) {
      this.router.push(this.props.linkBuilder({...item, ...this.props.linkBuilderProps}).href);
    }
  }

  renderGroup = (item) => {
    return (
      <span {...bemClassesGroup('content')}>
        <span {...bemClassesGroup('text')} children={item.search_group.name} />
      </span>
    );
  };

  render() {
    const {
      className, noError, desktop, iconPlaceholder, placeholder, queryParams,
      linkBuilder, linkBuilderProps, searchBoxContent
    } = this.props;
    const { autoFocus, searchValue } = this.state;
    const iconSearch = typeof iconPlaceholder === 'string' ? iconPlaceholder : '\ue900 ';

    return (
      <div {...classes(null, { noInteractive: desktop, menu: !desktop }, className)}>
        <div {...classes('form')}>
          {searchBoxContent}
          <AutocompleteInput
            {...classes('input')}
            ref={el => this.autocomplete = el}
            schema={{
              class: 'RuleString',
              placeholder: placeholder ? `${iconSearch}${placeholder}` : '\ue900 Поиск по сайту'
            }}
            url="common/search-index/suggest"
            queryParams={{
              project: __PROJECT__, // eslint-disable-line
              ...queryParams
            }}
            queryWord="query"
            dictKey="id"
            dictValue="name"
            minLength={1}
            onSelectItem={this.handleSelect}
            linkBuilder={linkBuilder}
            linkBuilderProps={linkBuilderProps}
            renderer={SearchBox.itemRender}
            onChange={this.handleChange}
            autoFocus={autoFocus}
            value={searchValue}
            noError={noError}
            groupBy="search_group.sortOrder"
            groupByRenderer={this.renderGroup}
            canSetStringValueByProps
          />
        </div>
      </div>
    );
  }
}

/**
 * Created by Vit on 16.07.2018.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import map from 'lodash/map';
import get from 'lodash/get';
import replace from 'lodash/replace';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import AskQuestion from 'subsys/ask-question';
import { LogoHeader } from 'components/header';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';
import { serverMenu } from 'components/header';
import { ModalButton, Content } from 'components/modal';
import LangSwitch from 'components/lang-switch';

import { renderLink } from 'src-sites/bild/body/footer-link';
import linksDomik from 'src-sites/domik/links';
import linksBild from 'src-sites/bild/links';
import defaultLogoBild from 'src-sites/bild/assets/logo-bild.jpg';

import { bildMenu } from './header/header-content';

import translates from './footer-i18n';

import config from '../../../config';

import 'styles/bild/body/_footer.scss';

const classesFooter = new Bem('footer');

@I18nHoc(translates())
export default class Footer extends Component {
  static componentCacheConfig = {
    strategy: 'template',
    enable: true
  };

  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
    location: PropTypes.shape({}),
  };

  static renderLogoContent(logoClasses) {
    return (
      <>
        <Icon {...logoClasses('icon', 'icon')} type={iconTypes.logoBildPotato} />
        <Icon {...logoClasses('icon', 'logo')} type={iconTypes.logoBild} />
      </>
    );
  }

  render() {
    const { i18n } = this;
    const { mainMenu, location, activeRoute } = this.props;
    const seoLocation = get(location, 'pathname');
    const footerMenu = serverMenu(bildMenu, mainMenu, i18n);
    const name = 'Bild.ua';
    const urlToFB = '//www.facebook.com/uabild';
    const urlToInsta = '//www.instagram.com/bild.ua_';
    const urlToTelegram = config.telegramlink.url;
    const telText = '+380(98) 656 34 02';
    const foundingDate = `2013-${(new Date()).getFullYear()}`;
    const department = i18n('department');

    return (
      <footer {...classesFooter(null, null, this.props.className)}>
        <Container body>
          <Row {...classesFooter('row', 'main')} mpColumn>
            <Col {...classesFooter('col')} mdCw={25} mdPr={16} mtCw={50} mtPr={16} mpPr={0}>
              <div {...classesFooter('item', 'logoContainer')}>
                <LogoHeader
                  {...classesFooter('logo')}
                  linkBuilder={linksBild.sectionBild}
                  builderProps={{ title: 'bild.ua' }}
                  renderContent={Footer.renderLogoContent}
                />
              </div>
              <div {...classesFooter('item')}>
                <span {...classesFooter('text')} children={i18n('logoDescription')} />
              </div>
              <div {...classesFooter('item')}>
                <LangSwitch activeRoute={activeRoute} />
              </div>
              {/*
              <div {...classesFooter('item')}>
                <Link
                  {...classesFooter('link')}
                  builder={linksBild.sectionSitemap}
                  builderProps={{ title: 'Карта сайта' }}
                >
                  <span {...classesFooter('linkContent')}>
                    <span {...classesFooter('text')} children="Карта сайта" />
                  </span>
                </Link>
              </div>
              */}
            </Col>
            <Col {...classesFooter('col', 'menu')} mdCw={25} mdPr={16} mtCw={50} mtPr={16} mpOrder={1} mpPr={0}>
              {map(footerMenu, (item, index) => (
                <div {...classesFooter('item')} key={index}>
                  <Link {...classesFooter('link')} {...item}>
                    <span {...classesFooter('linkContent')}>
                      <span {...classesFooter('text')} children={item.name} />
                    </span>
                  </Link>
                </div>
              ))}
            </Col>
            <Col {...classesFooter('col')} mdCw={25} mdPr={16} mtOrder={1} mtCw={50} mtPr={16} mpOrder={1} mpPr={0}>
              {renderLink.call(null, linksBild.sectionAbout, i18n('aboutBild'), i18n('aboutBild'), { rel: '', noIndex: false })}
              {renderLink.call(null, linksBild.sectionTerms, i18n('termsUser'), i18n('termsUser'), { rel: '', noIndex: false })}
              {renderLink.call(null, linksBild.sectionRegistrationCondition, i18n('linkToRegistrationConditionTitle'), i18n('linkToRegistrationConditionLabel'), { rel: '', noIndex: false })}
              {renderLink.call(null, linksDomik.sectionTariffPlans, i18n('tariffPlans'), i18n('tariffPlans'), { rel: '', noIndex: false, noRedirector: true, blank: true })}
            </Col>
            <Col {...classesFooter('col', 'info')} mdCw={25} mdPr={0} mtCw={50} mtPr={16} mtPl={16}>
              <div {...classesFooter('item')}>
                <span {...classesFooter('text')}>
                  {i18n('telTopic')}
                  <Link
                    {...classesFooter('link', 'tel noUnderline')}
                    href={`tel:${replace(telText, /[\s\(\)]/g, '')}`}
                    external
                  >
                    <span {...classesFooter('linkContent')}>
                      <span {...classesFooter('text')} children={telText} />
                    </span>
                  </Link>
                </span>
              </div>
              <div {...classesFooter('item')}>
                <ModalButton
                  {...classesFooter('link', 'toAskQuestion')}
                  buttonProps={{
                    label: i18n('askQuestionBtn'),
                    title: i18n('askQuestionBtn'),
                    contentLeft: true,
                    dataView: null
                  }}
                  size="big"
                  disableNotification={false}
                >
                  <Content closeButton noPadding adaptiveHeight>
                    <div {...classesFooter('modalContent')}>
                      <AskQuestion
                        {...classesFooter('blockAskQuestion')}
                        controller="support/ask-question/ask-us-question"
                        entity="ask_question"
                        url={seoLocation}
                        otherProps={{
                          notificationMessageSuccess: i18n('askQuestionNotification'),
                          inputPhoneProps: { noLabel: true, noElementBefore: true },
                          additionalMain: true,
                          headerTopic: true,
                          headerTopicText: i18n('askQuestionModalTopic')
                        }}
                      />
                    </div>
                  </Content>
                </ModalButton>
              </div>
              <div {...classesFooter('item')}>
                <span {...classesFooter('text')}>
                  {i18n('departmentTopic')}
                  <span children={department} />
                </span>
              </div>
              <div {...classesFooter('item')}>
                <Row>
                  <Col allAuto allPr={20}>
                    <Link
                      {...classesFooter('link', 'social fb')}
                      href={urlToFB}
                      title="facebook.com"
                      rel="nofollow"
                      // noRedirector
                      external
                      blank
                    >
                      <span {...classesFooter('linkContent')}>
                        <Icon {...classesFooter('icon')} type={iconTypes.socialFacebook} />
                      </span>
                    </Link>
                  </Col>
                  <Col allAuto allPr={20}>
                    <Link
                      {...classesFooter('link', 'social instagram')}
                      href={urlToInsta}
                      title="instagram.com"
                      rel="nofollow"
                      // noRedirector
                      external
                      blank
                    >
                      <span {...classesFooter('linkContent')}>
                        <Icon {...classesFooter('icon')} type={iconTypes.socialInstagram} />
                      </span>
                    </Link>
                  </Col>
                  <Col allAuto allPr={0}>
                    <Link
                      {...classesFooter('link', 'social telegram')}
                      href={urlToTelegram}
                      title={i18n('linkToTelegramTitle')}
                      rel="nofollow"
                      // noRedirector
                      external
                      blank
                    >
                      <span {...classesFooter('linkContent')}>
                        <Icon {...classesFooter('icon')} type={iconTypes.telegramFill} />
                      </span>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row {...classesFooter('row', 'bottom')} alignIcenter>
            <Col {...classesFooter('col')} mdPr={16} mtPr={16} mpPr={0}>
              <div {...classesFooter('item', 'copyContainer')}>
                <span {...classesFooter('text')}>
                  &copy; {`${foundingDate}\u00a0\u00a0\u00a0`}
                  <span {...classesFooter('copy')}>
                    <span {...classesFooter('copyContent')}>
                      <span {...classesFooter('text')} children={name} />
                    </span>
                  </span>
                </span>
              </div>
            </Col>
            <Col {...classesFooter('col')} mdAuto mpAuto allPr={0}>
              <div {...classesFooter('item', 'logoV')}>
                <Link
                  {...classesFooter('link', 'noUnderline')}
                  href="//vintage.com.ua"
                  title={i18n('linkToVintageTitle')}
                  rel="nofollow"
                  external
                  blank
                >
                  <span {...classesFooter('linkContent')}>
                    <span {...classesFooter('text')} children="by" />
                    <Icon {...classesFooter('icon', 'logoV')} type={iconTypes.logoV} />
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'Organization',
            logo: absoluteUrl(defaultLogoBild),
            name,
            url: absoluteUrl(''),
            telephone: telText,
            department,
            foundingDate,
            contactPoint: {
              telephone: telText,
              contactType: 'customer service',
              hoursAvailable: [
                {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Monday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Tuesday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Wednesday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Thursday',
                  opens: '09:00:00'
                }, {
                  closes: '18:00:00',
                  dayOfWeek: 'https://schema.org/Friday',
                  opens: '09:00:00'
                }
              ]
            },
            sameAs: [urlToFB, urlToInsta, urlToTelegram]
          }}
        />
      </footer>
    );
  }
}

import React, { PureComponent } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import { routerShape } from 'react-router';
import { connect } from 'react-redux';
import { authConnect, UserProp } from 'auth';
import get from 'lodash/get';
import map from 'lodash/map';
// import findIndex from 'lodash/findIndex';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import DropDown from 'components/drop-down';
import { Thumb } from 'components/image';
import {
  withSideMenu,
  MenuTopBtn,
  LogoHeader,
  // SearchInput,
  Menu,
  Account,
  FavoritesLink,
  serverMenu
} from 'components/header';
import SearchIndex from 'components/search-index';
import Icon, { iconTypes } from 'components/icon';
import Button from 'components/button';
import Accordion from 'components/accordion';

import linksBild from 'src-sites/bild/links';

import * as actions from 'visual-state/redux';

import SideMenu from './side-menu';

import translates from './header-i18n';

// import config from '../../../../config';

export const bildMenu = [
  {
    builder: linksBild.sectionLanding,
    contentType: 'stocks'
  },
  {
    builder: linksBild.sectionLanding,
    contentType: 'flatsNewbuild'
  },
  {
    builder: linksBild.sectionLanding,
    contentType: 'onMap'
  },
  {
    builder: linksBild.sectionLanding,
    contentType: 'cottageTowns'
  },
  {
    builder: linksBild.sectionLanding,
    contentType: 'commercialsNewbuild'
  },
  {
    builder: linksBild.sectionLanding,
    contentType: 'developers'
  }
  /*
  {
    href: config.telegramlink.url,
    rel: 'nofollow',
    noRedirector: false,
    external: true,
    blank: true,
    name: 'Акции'
  }
  */
];
const classes = new Bem('headerContent');
const bemClassesSearchInput = new Bem('searchInput');
const bemClassesAccordion = new Bem('headerAccordion');
const bemClassesFavorites = new Bem('favorites');
const bemClassesAccount = new Bem('account');
const bemClassesMenuTopBtn = new Bem('menuTopBtn');

@authConnect()
@withSideMenu
@connect(state => ({
  notification: state.notification,
  visualState: state.visualState
}), { ...actions })
@I18nHoc(translates())
export default class BildHeaderContent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),

    // Says which device is now active:
    desktop: PropTypes.bool, // desktop
    phone: PropTypes.bool, // phone

    // Auth props
    auth: UserProp,
    actionAuthLogout: PropTypes.func,
    checkPermission: PropTypes.func,
    checkPermissions: PropTypes.func,

    // Open sideMenu method
    onOpenMenu: PropTypes.func,

    // Close sideMenu method
    onCloseMenu: PropTypes.func,

    // Says sideMenu is opened
    menuOpened: PropTypes.bool,

    activeRoute: PropTypes.shape({}),

    // Says whether user clicked account button
    accountClicked: PropTypes.bool,

    notification: PropTypes.shape({
      count: PropTypes.number
    }),
    landing: PropTypes.shape({})
  };

  static contextTypes = {
    router: routerShape
  };

  static renderNoAuthContent() {
    return (
      <Icon
        {...bemClassesAccount('icon')}
        type={iconTypes.person}
        substrate={`<span class="${bemClassesAccount('img', 'substrate').className}"></span>`}
      />
    );
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.renderSearch = this.renderSearch.bind(this);
    this.searchItemSelected = this.searchItemSelected.bind(this);
    this.showMobileSearch = this.showMobileSearch.bind(this);
    this.isFirstLoad = this.isFirstLoad.bind(this);

    this.router = ctx.router;

    this.state = {
      isFirstLoad: true
    }
  }

  componentDidMount() {
    this.isFirstLoad();
  }

  isFirstLoad() {
    this.setState({ isFirstLoad: false })
  }

  searchItemSelected(it) {
    if (!this.props.desktop) {
      this.accordRef.toggle();
      this.searchRef.toggleFocus();
    }
  }

  showMobileSearch() {
    this.accordRef.toggle();
    this.searchRef.toggleFocus();
  }

  renderLogoContent(logoClasses) {
    return (
      <>
        <Icon {...logoClasses('icon', 'icon')} type={iconTypes.logoBildPotato} />
        <Icon {...logoClasses('icon', 'logo')} type={iconTypes.logoBild} />
      </>
    );
  }

  renderSearch() {
    return (
      <SearchIndex
        {...classes('search')}
        wRef={el => this.searchRef = el}
        iconPlaceholder=""
        placeholder={this.i18n('searchPlaceholder')}
        queryParams={{
          landing: true,
          entities: ['newbuild', 'developer'],
          order: [['relevancy', 'desc']]
        }}
        linkBuilder={linksBild.sectionLanding}
        onItemSelected={this.searchItemSelected}
        searchBoxContent={<Icon {...bemClassesSearchInput('icon', 'search')} type={iconTypes.searchBild} />}
        noError
      />
    );
  }

  renderAccordion(type) {
    return (
      <Accordion
        {...bemClassesAccordion()}
        ref={el => this.accordRef = el}
        noHeaderButton
      >
        <div {...bemClassesAccordion('content')} children={type === 'search' && this.renderSearch()} />
      </Accordion>
    );
  }

  toggleSearchForm =()=> {
    const showPhoneSearchOnMap = get(this, 'props.visualState.showPhoneSearchOnMap');
    this.props.togglePhoneSearchOnMap(!showPhoneSearchOnMap);
  };

  renderSearchButton(func) {
    return (
      <Button
        {...classes('btn', 'search accordion')}
        onClick={func}
        iconOnly
        customRenderer
      >
        <span {...classes('btnContent')}>
          <Icon
            {...classes('icon', 'search')}
            type={iconTypes.searchBild}
            substrate={`<span class="${classes('img', 'substrate').className}"></span>`}
          />
        </span>
      </Button>
    )
  }

  render() {
    const { i18n } = this;
    const {
      accountClicked,
      desktop, phone,
      onOpenMenu, onCloseMenu, menuOpened,
      auth, actionAuthLogout, checkPermission, checkPermissions, className,
      mainMenu, notification = {}, activeRoute
    } = this.props;
    const { isFirstLoad } = this.state;
    const sideMenuProps = {};
    if (desktop) {
      sideMenuProps.renderSearch = this.renderSearch;
      sideMenuProps.socialsAndPhones = true;
    }
    if (!desktop) {
      sideMenuProps.socialsAndPhones = false;
    }
    const btnLogout = (
      <Button
        {...classes('btn', 'exit')}
        onClick={actionAuthLogout}
        label={i18n('btnLogout')}
        title={i18n('btnLogout')}
      />
    );
    const permissions = get(this, 'props.auth.permissions', {});
    const isAdmin = permissions.length > 1;
    const btnAccount = (
      <Account
        {...classes('account')}
        onAccountClick={isAdmin ? onOpenMenu : null}
        renderContent={BildHeaderContent.renderNoAuthContent}
      />
    );
    const builtMenu = serverMenu(bildMenu, mainMenu, i18n);
    return (
      <div {...classes({ extra: className })} data-test="menuTop">
        <LogoHeader
          linkBuilder={linksBild.sectionBild}
          builderProps={{ title: 'bild.ua' }}
          renderContent={this.renderLogoContent}
        />
        {desktop &&
          <>
            {!activeRoute.noHeaderSearch && this.renderSearch()}
            <Menu
              {...classes('menu')}
              mainMenu={builtMenu}
            />
          </>
        }
        {!activeRoute.noHeaderSearch && !desktop &&
          this.renderSearchButton(this.showMobileSearch)
        }
        {activeRoute.mainSearch && phone &&
          this.renderSearchButton(this.toggleSearchForm)
        }
        {!activeRoute.noHeaderSearch && isFirstLoad &&
          <div {...classes('btn', 'search accordion dummy', 'btn')}>
            <span {...classes('btnContent', null, 'btn__content')}>
              <Icon
                {...classes('icon', 'search')}
                type={iconTypes.searchBild}
                substrate={`<span class="${classes('img', 'substrate').className}"></span>`}
              />
            </span>
          </div>
        }
        <FavoritesLink
          builder={linksBild.pageFavorites}
          builderProps={{ project: 'bild' }}
          otherPropsIcon={{
            type: iconTypes.favoritesBorder,
            substrate: `<span class="${bemClassesFavorites('img', 'substrate').className}"></span>`
          }}
          otherPropsLink={{
            blank: false
          }}
        />
        {!desktop &&
          <MenuTopBtn
            onOpenSideMenu={onOpenMenu}
            notificationCount={notification.count}
          />
        }
        {isFirstLoad &&
          <>
            <div {...bemClassesMenuTopBtn({ modifiers: 'dummy', extra: className })}>
              <div {...bemClassesMenuTopBtn('btn', 'menu', 'btn')}>
                <span {...bemClassesMenuTopBtn('btnContent', null, 'btn__content')}>
                  <Icon
                    {...bemClassesMenuTopBtn('icon')}
                    type={iconTypes.menu}
                  />
                </span>
              </div>
            </div>
            <div {...classes('account', 'dummy', 'account')}>
              <div {...bemClassesAccount('btn', 'account', 'btn')}>
                <span {...bemClassesAccount('btnContent', null, 'btn__content')}>
                  {BildHeaderContent.renderNoAuthContent()}
                </span>
              </div>
            </div>
          </>
        }
        {(desktop && (isAdmin || !auth.authenticated)) && btnAccount}
        {desktop && auth.authenticated && !isAdmin &&
          <DropDown
            {...classes('account', 'dd')}
            inForm
            list
            wAuto
          >
            <Button
              {...bemClassesAccount('btn', 'account auth')}
              customRenderer
            >
              <span {...bemClassesAccount('btnContent')} data-test="enterButton">
                <Thumb
                  {...bemClassesAccount('icon', 'photo')}
                  image={{ id: auth.avatar }}
                  updateEvent="changeAvatar"
                  tagSpan
                />
              </span>
            </Button>
            <div {...classes('accountContent')} children={btnLogout} />
          </DropDown>
        }
        <LazyHydrate whenIdle>
          <SideMenu
            auth={auth}
            actionAuthLogout={actionAuthLogout}
            desktop={desktop}
            phone={phone}
            accountClicked={accountClicked}
            menuOpened={menuOpened}
            onMenuClose={onCloseMenu}
            mainMenu={builtMenu}
            activeRoute={activeRoute}
            checkPermission={checkPermission}
            checkPermissions={checkPermissions}
            {...sideMenuProps}
          />
          {!activeRoute.noHeaderSearch && !desktop && this.renderAccordion('search')}
        </LazyHydrate>
      </div>
    );
  }
}

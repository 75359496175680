import menuBaseTranslates from '../../../../src-core/components/header/side-menu/menu-i18n.json';

import menuTranslates from '../menu-i18n.json';

export default function () {
  return {
    ...menuBaseTranslates,
    ...menuTranslates,

    btnLogout: {
      ru: 'Выйти',
      ua: 'Вийти'
    },
    searchPlaceholder: {
      ru: 'Название ЖК или Застройщика',
      ua: 'Назва ЖК або Забудовника'
    }
  };
}

/**
 * Created by Vit on 26.05.2017.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import limitMax from 'helpers/limitMaxCount';
import { subscribe, unsubscribe } from 'helpers/global-events';
import { authConnect, UserProp, perms } from 'auth';
import { Thumb } from 'components/image';
import Button from 'components/button';

import translates from './account-i18n.json';

const classes = new Bem('account');

@authConnect()
@connect(state => ({ notification: state.notification }))
@I18nHoc(translates)
export default class Account extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onAccountClick: PropTypes.func,
    auth: UserProp,
    checkPermissions: PropTypes.func,
    actionDoLogin: PropTypes.func,
    actionAuthGetData: PropTypes.func,
    notification: PropTypes.shape({
      count: PropTypes.number
    }),
    renderContent: PropTypes.func
  };

  constructor(...props) {
    super(...props);
    this.handleProfileUpdate = this.handleProfileUpdate.bind(this);

    this.state = {
      clientRendered: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ clientRendered: true });

    // *** Auto re-request auth data every 5 minutes
    setInterval(() => this.props.actionAuthGetData(), 60 * 5 * 1000);
    subscribe('userProfileUpdated', this.handleProfileUpdate);
    subscribe('gLangChanged', this.handleLangChanged);
    this._checkPush(this.props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this._checkPush(this.props);
  }

  componentWillUnmount() {
    unsubscribe('userProfileUpdated', this.handleProfileUpdate);
    unsubscribe('gLangChanged', this.handleLangChanged);
  }

  onAccountClick(authenticated) {
    if (authenticated) return this.props.onAccountClick(true);
    this.props.actionDoLogin();
  }

  /**
   * Show push notifications to admins
   * @param props
   * @private
   */
  _checkPush(props) {
    if (!this.props.auth.authenticated) {
      return;
    }

    if (!this.props.checkPermissions([
      perms.DOMIK_NOTIFICATIONS_ADMIN,
      perms.FORUM_NOTIFICATIONS_ADMIN
    ])) {
      return;
    }

    if (!window.Notification) {
      return;
    }

    if (!props.notification) {
      return;
    }

    // Request permissions if needed
    if (!window.Notification.permission !== 'denied') {
      window.Notification.requestPermission();
    }

    const { count } = props.notification;
    if (count > this.props.notification.count && window.Notification.permission !== 'denied') {
      // eslint-disable-next-line
      const n = new window.Notification(this.i18n('notificationCount', { placeholder: count }));
    }
  }

  handleProfileUpdate() {
    this.props.actionAuthGetData();
  }

  handleLangChanged = () => {
    this.forceUpdate();
  }

  renderNoAuthContent(renderContent) {
    const renderContentMain = <span {...classes('text')} children={this.i18n('btnLogin')} />;

    return renderContent ? renderContent() : renderContentMain;
  }

  render() {
    if (!this.state.clientRendered) return null;

    const { auth = {}, className, renderContent, notification = {} } = this.props;
    const { authenticated, id, avatar, name } = auth;

    return (
      <div {...classes({ extra: className })} data-test="accountButton">
        <Button
          {...classes('btn', { account: true, auth: authenticated })}
          onClick={() => this.onAccountClick(authenticated)}
          title={authenticated ? `${name}, ID: ${id}` : this.i18n('btnLogin')}
          customRenderer
        >
          <>
            <span {...classes('btnContent')} data-test="enterButton">
              {authenticated &&
                <Thumb
                  {...classes('icon', 'photo')}
                  image={{ id: avatar }}
                  updateEvent="changeAvatar"
                  tagSpan
                />
              }
              {!authenticated && this.renderNoAuthContent(renderContent)}
            </span>
            {(authenticated && notification.count > 0) && <span {...classes('text')} children={limitMax(notification.count)} />}
          </>
        </Button>
      </div>
    );
  }
}
